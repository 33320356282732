import styled from 'styled-components';

import colors from 'styles/colors';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const StyledPage = styled.div`
  padding: 96px 140px 41px 140px;

  @media only screen and (max-width: ${metrics.mobile}) {
    padding: 50px 32px 20px 32px;
  }
`;

export const Paragraph = styled.div`
  margin-bottom: 80px;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin-bottom: 45px;
  }
`;

export const ParagraphTitle = styled.div`
  text-transform: uppercase;
  font-weight: ${fonts.weight.semiBold};
  font-size: 20px;
  margin-bottom: 30px;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin-bottom: 20px;
    font-size: 18px;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;

export const BoldText = styled.span`
  font-weight: ${fonts.weight.semiBold};
`;

export const ItalicText = styled(Text)`
  font-style: italic;
  font-weight: ${fonts.weight.medium};
`;

export const TextLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  font-size: 18px;
  line-height: 30px;
  color: ${colors.link};
  cursor: pointer;

  &:hover {
    color: ${colors.linkHover};
  }

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: 16px;
  }
`;

import styled from 'styled-components';

import { Page } from 'styles/commonStyledComponents';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const StyledPage = styled(Page)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BackNavigation = styled.div`
  align-self: flex-start;
`;

export const Form = styled.form`
  max-width: 450px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: ${fonts.size.header};
  margin-bottom: 15px;
  text-align: center;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: 26px;
  }
`;

export const Subtitle = styled.p`
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  margin-bottom: 40px;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin-bottom: 30px;
    font-size: ${fonts.size.medium};
  }
`;

export const Text = styled.div`
  text-align: center;
  margin: 20px 50px 30px 50px;
`;

export const TextBold = styled.span`
  font-weight: ${fonts.weight.semiBold};
`;

export const ButtonWrapper = styled.div`
  margin-top: 25px;
  width: 100%;
`;

export const USER_TYPE = {
  LEGACY: 6,
};

export const PRODUCTS = {
  UF_3M: 1,
  IM_LIFETIME: 2,
  LEGACY_UF_LIFETIME: 3,
  UF_LIFETIME: 9,
  UF_LIFETIME_UPGRADE: 10,
  IM_LIFETIME_UPGRADE: 13,
  EA1: 26,
  BUNDLE_3: 27,
};

export const PRICE = {
  IM_LIFETIME: 29999,
};

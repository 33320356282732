import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import ErrorModal from 'components/ErrorModal';
import ErrorText from 'components/ErrorText';
import Loader from 'components/Loader';
import useModal from 'hooks/useModal';
import { useForm } from 'hooks/useReactHookForm';
import { PaymentFormValues } from 'pages/Payment/types';
import { useAppDispatch, useAppSelector } from 'resources/hooks';
import * as purchaseActions from 'resources/purchase/purchase.actions';
import * as purchaseSelectors from 'resources/purchase/purchase.selectors';

import PayByCardFrom from '../PayByCardForm';
import PayWithPaypalForm from '../PayWithPaypalForm';
import ProductInfo from '../ProductInfo';
import { ProviderRadio, ProviderRadioValue } from '../ProviderRadio';
import * as SC from './PaymentSection.styled';

const PaymentSection = () => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();

  const product = useAppSelector((state) => purchaseSelectors.getProduct(state));
  const newProduct = useAppSelector((state) => purchaseSelectors.getNewProduct(state));

  const [visibleErrorModal, setHiddenErrorModal, setVisibleErrorModal, textErrorModal] = useModal(false);

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
    globalError,
    mapFormErrors,
    register,
  } = useForm<PaymentFormValues>({
    defaultValues: {
      provider: ProviderRadioValue.Stripe,
    },
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const paymentProvider = watch('provider');

  const onSubmit = handleSubmit(async (values) => {
    if (values.provider === ProviderRadioValue.PayPal) {
      try {
        const orderData = await dispatch(purchaseActions.initPaypalOrder(newProduct.id || product.id, values.source));
        window.location.href = orderData.approval_url;
      } catch (e) {
        const error = e as any;

        if (error && error.status === 500) {
          setVisibleErrorModal(error.data);
        } else {
          if (error.data.non_field_errors) {
            setVisibleErrorModal(error.data.non_field_errors.join(' '));
          }

          mapFormErrors(e);
        }
      }
    } else {
      if (!stripe || !elements) {
        return;
      }

      const purchaseInfo = {
        productId: newProduct.id || product.id,
        price: newProduct.id ? newProduct.current_price : product.current_price,
        how_did_you_hear: values.source,
        free: newProduct.id ? newProduct.free : product.free,
      };

      if (purchaseInfo.free) {
        try {
          await dispatch(purchaseActions.payByCard(null, purchaseInfo));
          history.push(`/confirmation/${purchaseInfo.productId}`, { canViewConfirmationPage: true });
        } catch (e) {
          const payError = e as any;

          if (payError && payError.status === 500) {
            setVisibleErrorModal(payError.data);
          } else {
            if (payError.data.non_field_errors) {
              setVisibleErrorModal(payError.data.non_field_errors.join(' '));
            }

            mapFormErrors(payError);
          }
        }
      } else {
        const cardElement = elements.getElement(CardNumberElement);
        const { error, token } = await stripe.createToken(cardElement!);
        if (token) {
          try {
            await dispatch(purchaseActions.payByCard(token.id, purchaseInfo));
            history.push(`/confirmation/${purchaseInfo.productId}`, { canViewConfirmationPage: true });
          } catch (e) {
            const payError = e as any;

            if (payError && payError.status === 500) {
              setVisibleErrorModal(payError.data);
            } else {
              if (payError.data.non_field_errors) {
                setVisibleErrorModal(payError.data.non_field_errors.join(' '));
              }

              mapFormErrors(payError);
            }
          }
        }
        if (error) {
          setVisibleErrorModal(error.message);
        }
      }
    }
  });

  return (
    <SC.PaymentSection>
      <SC.Payment onSubmit={onSubmit} id="payment-form">
        <SC.PaymentForm>
          <Controller control={control} name="provider" render={({ field }) => <ProviderRadio {...field} />} />
          {paymentProvider === ProviderRadioValue.Stripe ? (
            <PayByCardFrom register={register} errors={errors} />
          ) : (
            <PayWithPaypalForm register={register} errors={errors} />
          )}
          {globalError && <ErrorText error={globalError} />}
        </SC.PaymentForm>
      </SC.Payment>

      <SC.ProductInfo>
        <ProductInfo />

        <SC.ProductInfoBelow>
          <SC.ProductInfoBelowText>Product Info Below</SC.ProductInfoBelowText>
          <SC.ProductInfoBelowIcon />
        </SC.ProductInfoBelow>
      </SC.ProductInfo>

      <ErrorModal visible={visibleErrorModal} onClose={setHiddenErrorModal} text={textErrorModal} />

      <Loader visible={isSubmitting} />
    </SC.PaymentSection>
  );
};

export default PaymentSection;

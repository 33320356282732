import React, { useCallback, useState } from 'react';

import CustomerImage1 from 'assets/customer-1.jpeg';
import CustomerImage2 from 'assets/customer-2.jpg';
import CustomerImage3 from 'assets/customer-3.jpg';

import * as SC from '../../CourseDescription.styled';

const feedback = [
  {
    id: 0,
    image: CustomerImage1,
    author: 'Sadie Lincoln',
    position: 'Founder, Barre3',
    text: `What I find the most exceptional about Dandapani 
    is how dedicated he is to giving each of us practical tools that 
    integrate seamlessly into our modern-day lives. 
    He takes complex ideas around the mind and awareness and simplifies 
    them in a captivating way that is truly transformational...`,
  },
  {
    id: 1,
    image: CustomerImage2,
    author: 'Rob Nixon',
    position: 'CEO & Founder Panalitix',
    text: `Dandapani delivers his message in a refreshing, real, smart, 
    funny and highly practical way. He doesn't talk religion - just life. 
    He was able to turn his Monk teachings into practical advice for everyday living...`,
  },
  {
    id: 2,
    image: CustomerImage3,
    author: 'Tim Ferriss',
    position: 'Angel Investor, Author, The Four Hour Workweek',
    text: "For the first time in my life, I'm enjoying making my bed in the morning. Thanks, Dandapani!",
  },
];

const Feedback = () => {
  const [currentQuote, setCurrentQuote] = useState(feedback[0].id);

  const onPreviousQuoteClick = useCallback(() => {
    setCurrentQuote(currentQuote === 0 ? 2 : currentQuote - 1);
  }, [currentQuote]);

  const onNextQuoteClick = useCallback(() => {
    setCurrentQuote(currentQuote === 2 ? 0 : currentQuote + 1);
  }, [currentQuote]);

  return (
    <SC.Content>
      <SC.Title>Customers Feedback</SC.Title>
      <SC.SubTitle>What others have to say…</SC.SubTitle>
      <SC.Quote>
        <SC.QuoteAuthorImage src={feedback[currentQuote].image} />
        <SC.QuoteTextWrapper>
          <SC.QuoteText>{feedback[currentQuote].text}</SC.QuoteText>
        </SC.QuoteTextWrapper>
        <SC.QuoteList>
          <SC.PreviousQuote onClick={onPreviousQuoteClick} />
          <SC.QuoteAuthor>
            <SC.QuoteAuthorName>{feedback[currentQuote].author}</SC.QuoteAuthorName>
            <SC.QuoteAuthorPosition>{feedback[currentQuote].position}</SC.QuoteAuthorPosition>
          </SC.QuoteAuthor>
          <SC.NextQuote onClick={onNextQuoteClick} />
        </SC.QuoteList>
      </SC.Quote>
    </SC.Content>
  );
};

export default Feedback;

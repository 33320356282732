import { CLEAR_PURCHASE } from 'resources/purchase/purchase.actions';
import * as cookie from 'utils/cookie';

import * as userApi from './user.api';

export const UPDATED_USER = 'UPDATED_USER';
export const CHANGED_EMAIL = 'CHANGED_EMAIL';
export const SIGN_UP_USER = 'SIGN_UP_USER';
export const SIGN_IN_USER = 'SIGN_IN_USER';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const SET_AUTH = 'SET_AUTH';
export const GET_USER = 'GET_USER';
export const LEGACY_USER = 'LEGACY_USER';
export const FETCHED_TOKEN = 'FETCHED_TOKEN';

export const getUser = () => async (dispatch) => {
  const user = await userApi.getUser();
  dispatch({ type: GET_USER, user });
};

export const signOut = () => (dispatch) => {
  window.localStorage.removeItem('token');
  cookie.set('token', '');
  window.location.assign(window.location.href.replace(window.location.search, ''));
  dispatch({ type: SIGN_OUT_USER });
  dispatch({ type: CLEAR_PURCHASE });
};

export const verifyToken = ({ token }) => async (dispatch) => {
  try {
    const data = await userApi.verifyToken({ token });
    window.localStorage.setItem('token', data.token);
    dispatch({ type: SET_AUTH });
    await dispatch(getUser());
  } catch (error) {
    await dispatch(signOut());
  }
};

export const setAuthenticated = () => (dispatch) => {
  dispatch({ type: SET_AUTH });
};

export const signIn = (userInfo) => async (dispatch) => {
  const signInInfo = await userApi.signIn(userInfo);
  if (signInInfo.token) {
    window.localStorage.setItem('token', signInInfo.token);
    dispatch({ type: SIGN_IN_USER, user: signInInfo.user });
  } else {
    dispatch({ type: LEGACY_USER, legacyUser: signInInfo });
  }
  return signInInfo;
};

export const signUp = (userInfo) => async (dispatch) => {
  const signUpInfo = await userApi.signUp(userInfo);
  window.localStorage.setItem('token', signUpInfo.token);
  dispatch({ type: SIGN_UP_USER, user: signUpInfo.user });
};

export const updateUser = (userId, userInfo) => async (dispatch) => {
  const user = await userApi.updateUser(userId, userInfo);
  dispatch({ type: UPDATED_USER, user });
};

export const changePassword = async (passwordInfo) => {
  await userApi.changePassword(passwordInfo);
};

export const changeEmail = (emailInfo) => async (dispatch) => {
  const user = await userApi.changeEmail(emailInfo);
  dispatch({ type: CHANGED_EMAIL, user });
};

export const requestResetPassword = async (emailInfo) => {
  await userApi.requestResetPassword(emailInfo);
};

export const resetPassword = async (passwordInfo, uidb64, token) => {
  await userApi.resetPassword(passwordInfo, uidb64, token);
};

export const fetchToken = ({ temporaryToken }) => async (dispatch) => {
  try {
    const tokenInfo = await userApi.fetchToken({ temporaryToken });
    window.localStorage.setItem('token', tokenInfo.token);
    dispatch({ type: SET_AUTH });
    await dispatch(getUser());
  } catch (error) {
    await dispatch(signOut());
  }
};

export const confirmEmail = (id, token) => async () => {
  await userApi.confirmEmail(id, token);
};

import React from 'react';

import * as SC from './ErrorText.styled';

interface Props {
  error?: React.ReactNode;
  absolutePosition?: boolean;
  negativeBottomMargin?: boolean;
}

const ErrorText = ({ error, absolutePosition, negativeBottomMargin }: Props) => (
  <SC.Errors absolutePosition={absolutePosition} negativeBottomMargin={negativeBottomMargin}>
    <SC.Error data-testid="error-message">{error}</SC.Error>
  </SC.Errors>
);

export default ErrorText;

import React from 'react';
import PropTypes from 'prop-types';

import * as SC from './PageHeader.styled';

const PageHeader = ({ title, onNavigateBack, backNavigationText }) => (
  <SC.PageHeader>
    <SC.BackNavigation>
      <SC.BackNavigationImage />
      <SC.BackNavigationText onClick={onNavigateBack}>{backNavigationText}</SC.BackNavigationText>
    </SC.BackNavigation>
    <SC.PageTitle>{title}</SC.PageTitle>
  </SC.PageHeader>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onNavigateBack: PropTypes.func.isRequired,
  backNavigationText: PropTypes.string,
};

PageHeader.defaultProps = {
  backNavigationText: 'Go back',
};

export default PageHeader;

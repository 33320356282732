import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Input from 'components/Input';
import LegacyUserModal from 'components/LegacyUserModal';
import LegacyUserTooltip from 'components/LegacyUserTooltip';
import useModal from 'hooks/useModal';
import { FormError, useForm } from 'hooks/useReactHookForm';
import * as purchaseSelectors from 'resources/purchase/purchase.selectors';
import * as userActions from 'resources/user/user.actions';

import * as SC from './SignInForm.styled';

interface FormValues extends FormError {
  email: string;
  password: string;
}

const SignInForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const productId = useSelector((state: any) => purchaseSelectors.getInitialProductId(state));
  const [visibleLegacyModal, setHiddenLegacyModal, setVisibleLegacyModal] = useModal(false);

  const {
    handleSubmit,
    register,
    mapFormErrors,
    formState: { errors, isSubmitting },
    globalError,
  } = useForm<FormValues>();

  const onSubmit = handleSubmit(async (values) => {
    try {
      const params = new URLSearchParams(history.location.search);

      const signInInfo = await dispatch(userActions.signIn(values) as any);
      if (signInInfo.token) {
        if (productId) {
          history.push(`/payment/${productId}`);
        } else if (params.has('rituals')) {
          history.replace(`/subscribe/${params.get('rituals')}`);
        } else {
          history.push('/');
        }
      } else {
        setVisibleLegacyModal();
      }
    } catch (error) {
      mapFormErrors(error);
    }
  });

  const onNavigateLegacyToSignUp = useCallback(() => {
    history.push('/sign-up');
  }, [history]);

  return (
    <SC.SignInForm onSubmit={onSubmit}>
      <LegacyUserTooltip />
      <Input
        label="Email Address"
        type="email"
        error={errors.email}
        placeholder="Your Email Address"
        {...register('email', {
          required: true,
          maxLength: 127,
        })}
        required
      />
      <Input
        label="Password"
        type="password"
        error={errors.password}
        placeholder="Your Password"
        {...register('password', {
          required: true,
          maxLength: 127,
        })}
        required
      />
      {globalError && <ErrorText error={globalError} negativeBottomMargin />}
      <SC.CreateAccount to={`/sign-up?${history.location.search}`}>
        <SC.CreateAccountIcon />
        <SC.CreateAccountLink>Haven't registered yet? Create an account.</SC.CreateAccountLink>
      </SC.CreateAccount>
      <SC.ButtonWrapper>
        <Button title="Sign In" type="submit" disabled={isSubmitting} />
      </SC.ButtonWrapper>
      <LegacyUserModal
        visible={visibleLegacyModal}
        onClose={setHiddenLegacyModal}
        onNavigateToSignUp={onNavigateLegacyToSignUp}
      />
    </SC.SignInForm>
  );
};

export default SignInForm;

import 'react-app-polyfill/stable';
import './styles/base.css';
import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import * as cookie from 'utils/cookie';

import App from './App';
import { store } from './resources/store';
import * as userActions from './resources/user/user.actions';

if (window.location.origin.indexOf('https://app.') === 0) {
  TagManager.initialize({
    gtmId: 'GTM-NCXQWRT',
  });
}

const History: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const history = useHistory();

  React.useEffect(() => {
    const { push, replace } = history;

    const patch = (f: any) => (to: any, state: any) => {
      if (typeof to === 'string') {
        return f(to, {
          ...state,
          oldPath: window.location.pathname,
          newPath: to,
        });
      }

      return f({
        ...to,
        state: {
          ...to.state,
          oldPath: window.location.pathname,
          newPath: to.pathname,
        },
      });
    };

    history.push = patch(push);
    history.replace = patch(replace);
  }, []);

  return children;
};

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://f3bc22dacdc047609c7cc323bb86c3ca@o310765.ingest.sentry.io/4504983384621056',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
  });
}

(async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const queryToken = searchParams.get('token');

  if (queryToken) {
    cookie.set('token', queryToken);
  }

  const cookieToken = cookie.get('token');

  const token = window.localStorage.getItem('token');
  if (token) {
    await store.dispatch(userActions.verifyToken({ token }));
  } else if (cookieToken) {
    await store.dispatch(userActions.fetchToken({ temporaryToken: cookieToken }));
  }

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <History>
          <LastLocationProvider>
            <App />
          </LastLocationProvider>
        </History>
      </BrowserRouter>
    </Provider>
  );
})();

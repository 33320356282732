const fontsSrc = 'https://fonts.googleapis.com/css?family=Montserrat:400,500';

const type = {
  base: 'Montserrat',
};

const size = {
  pageHeader: '34px',
  title: '40px',
  header: '30px',
  subtitle: '16px',
  regular: '14px',
  medium: '13px',
  small: '12px',
  extraSmall: '11px',
  tiny: '10px',
};

const weight = {
  light: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
};

const lineHeight = {
  regular: '26px',
};

export default {
  type,
  size,
  weight,
  lineHeight,
  fontsSrc,
};

import { AVAILABLE_PRODUCTS, PURCHASED_PRODUCTS } from './product.actions';

const initialState = {
  purchasedProducts: [],
  availableProducts: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PURCHASED_PRODUCTS:
      return {
        ...state,
        purchasedProducts: action.products,
      };
    case AVAILABLE_PRODUCTS:
      return {
        ...state,
        availableProducts: action.products.map((product) => {
          if (product.meta.oldPriceCrossed) {
            return {
              ...product,
              regular_price: Number(`${product.meta.oldPrice}${product.meta.oldPriceCents || '00'}`),
            };
          }
          return product;
        }),
      };
    default:
      return state;
  }
};

export default reducer;

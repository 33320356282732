import styled from 'styled-components';

import metrics from 'styles/metrics';

export const StyledPage = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Main = styled.div`
  max-width: 450px;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: 26px;
  }
`;

export const Subtitle = styled.div`
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 40px;
`;

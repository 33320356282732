import styled from 'styled-components';

import { ReactComponent as LogoImage } from 'assets/dandapani-logo.svg';
import { ReactComponent as ProfileIcon } from 'assets/profile.svg';
import colors from 'styles/colors';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const Header = styled.div`
  padding: 20px 140px;
  background-color: ${colors.backgroundTheme};
  color: ${colors.fontSecondary};

  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${metrics.mobile}) {
    padding: 27px 30px 30px 30px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled(LogoImage)`
  margin-right: 15px;
`;

export const PrimaryText = styled.div`
  margin-right: 12px;
  font-weight: ${fonts.weight.bold};
  font-size: ${fonts.size.small};
  letter-spacing: 0.15em;
  text-transform: uppercase;

  @media only screen and (max-width: ${metrics.mobile}) {
    display: none;
  }
`;

export const SecondaryText = styled.div`
  font-weight: ${fonts.weight.light};
  font-size: ${fonts.size.small};
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @media only screen and (max-width: ${metrics.tablet}) {
    display: none;
  }
`;

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ProfileImage = styled(ProfileIcon)`
  margin-right: 11px;
`;

export const UserName = styled.div`
  font-weight: ${fonts.weight.semiBold};
  font-size: ${fonts.size.small};
  margin-right: 16px;

  &:after {
    content: '';
    height: 12px;
    border-right: 1px solid #ffffff;
    padding-left: 16px;
  }
`;

export const LogOutText = styled.div`
  font-weight: ${fonts.weight.regular};
  font-size: ${fonts.size.small};
  text-transform: uppercase;
  cursor: pointer;
`;

import styled from 'styled-components';

import colors from 'styles/colors';
import fonts from 'styles/fonts';

export const Errors = styled.div<{ absolutePosition?: boolean; negativeBottomMargin?: boolean }>`
  position: ${(props) => props.absolutePosition && 'absolute'};
  align-self: flex-start;
  margin-top: ${(props) => !props.absolutePosition && '-5px'};
  margin-bottom: ${(props) => !props.absolutePosition && '5px'};
  margin-bottom: ${(props) => props.negativeBottomMargin && '-14px'};
`;

export const Error = styled.p`
  color: ${colors.error};
  letter-spacing: 0.05em;
  font-size: ${fonts.size.tiny};
  line-height: 19px;
`;

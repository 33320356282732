import styled from 'styled-components';

import colors from 'styles/colors';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const TabView = styled.div``;

export const Tabs = styled.div`
  display: flex;
`;

export const Tab = styled.div`
  color: ${colors.fontPrimary};
  font-size: ${fonts.size.subtitle};
  letter-spacing: -0.02em;

  margin-right: 50px;
  padding-bottom: 13px;
  border-bottom: ${(props) => props.selected && `2px solid ${colors.primary}`};
  cursor: pointer;

  &:last-child {
    margin-right: 0px;
  }

  @media only screen and (max-width: ${metrics.mobile}) {
    margin-right: 35px;
    font-size: ${fonts.size.regular};
  }
`;

export const TabContent = styled.div`
  padding-top: 55px;
  max-width: 450px;
`;

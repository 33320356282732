import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import colors from 'styles/colors';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const Page = styled.div`
  padding: 27px 140px 121px 140px;

  @media only screen and (max-width: ${metrics.mobile}) {
    padding: 30px;
  }
`;

export const App = styled.div`
  width: 100%;

  box-sizing: border-box;

  font-family: ${fonts.type.base};
  font-size: ${fonts.size.regular};
  font-weight: ${fonts.weight.regular};
  line-height: ${fonts.lineHeight.regular};
  color: ${colors.fontPrimary};

  background-color: ${colors.background};
`;

export const Link = styled(NavLink)`
  font-size: ${fonts.size.small};
  line-height: 26px;
  color: ${colors.link};
  cursor: pointer;

  &:hover {
    color: ${colors.linkHover};
  }
`;

export const TextLink = styled.a`
  font-size: ${fonts.size.small};
  line-height: 26px;
  color: ${colors.link};
  cursor: pointer;

  &:hover {
    color: ${colors.linkHover};
  }
`;

export const BackLink = styled(NavLink)`
  text-transform: uppercase;
  font-size: ${fonts.size.small};
  line-height: 26px;
  color: ${colors.link};
  cursor: pointer;

  &:hover {
    color: ${colors.linkHover};
  }
`;

import React, { useEffect } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ErrorModal from 'components/ErrorModal';
import useModal from 'hooks/useModal';
import * as purchaseActions from 'resources/purchase/purchase.actions';
import stringConstants from 'utils/stringConstants';

import * as SC from './CompletePaypalOrder.styled';

const CompletePaypalOrder = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const [visibleErrorModal, setHiddenErrorModal, setVisibleErrorModal, textErrorModal] = useModal(false);

  useEffect(() => {
    (async () => {
      const order = qs.parse(location.search, { ignoreQueryPrefix: true });
      const orderInfo = {
        payment_id: order.paymentId,
        token: order.token,
        payer_id: order.PayerID,
      };
      try {
        const productInfo = await dispatch(purchaseActions.completePaypalOrder(params.orderId, orderInfo));
        history.push(`/confirmation/${productInfo.id}`, { canViewConfirmationPage: true });
      } catch (error) {
        if (!error.data?.non_field_errors) {
          setVisibleErrorModal(stringConstants.serverError);
        } else {
          setVisibleErrorModal(error.data.non_field_errors.join(' '));
        }
      }
    })();
  }, [location, params, dispatch, history, setVisibleErrorModal]);

  return (
    <SC.StyledPage>
      <SC.Title>Processing your order...</SC.Title>
      <SC.Subtitle>Your order is processing. You will be redirected soon.</SC.Subtitle>

      <ErrorModal visible={visibleErrorModal} onClose={setHiddenErrorModal} text={textErrorModal} />
    </SC.StyledPage>
  );
};

export default CompletePaypalOrder;

import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-x: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(26, 25, 75, 0.53);
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border-radius: 3px;
  font-size: 30px;
  padding: 1em;
  position: relative;
  margin-bottom: 0.25em;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s;
  color: #ffffff;
`;

export const Loader = styled.div`
  position: relative;
  background-color: currentcolor;
  border-radius: 50%;

  display: inline-block;
  width: 1em;
  height: 1em;
  color: inherit;
  vertical-align: middle;
  pointer-events: none;

  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 0.25em;
    height: 0.25em;
    border-radius: 50%;
    opacity: 0.8;
  }

  &:after {
    left: -0.5em;
    top: -0.25em;
    background-color: currentcolor;
    transform-origin: 0.75em 1em;
    animation: ${rotate} 1s linear infinite;
    opacity: 0.6;
  }

  &:before {
    left: -1.25em;
    top: -0.75em;
    background-color: currentcolor;
    transform-origin: 1.5em 1em;
    animation: ${rotate} 2s linear infinite;
  }
`;

export const get = (cookieName) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i].trimStart();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return '';
};

export const set = (name, value) => {
  document.cookie = `${name}=${value}; ${process.env.NODE_ENV !== 'development' && 'domain=.dandapani.org;'} path=/`;
};

import styled from 'styled-components';

import metrics from 'styles/metrics';

export const NotFoundPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 0px 32px;
`;

export const Error = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ErrorCode = styled.div`
  font-weight: 500;
  font-size: 86px;
  line-height: 105px;
  margin-bottom: 22px;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: 74px;
    line-height: 86px;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  letter-spacing: 0.15em;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 12px;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: 12px;
  }
`;

export const Subtitle = styled.div`
  text-align: center;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: 12px;
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 275px;
  width: 100%;
  margin-top: 47px;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin-top: 30px;
  }
`;

import React, { useId, useState } from 'react';
import { FieldError } from 'react-hook-form';

import PasswordHideIcon from 'assets/hide-password.svg';
import PasswordShowIcon from 'assets/show-password.svg';
import { getFieldError } from 'hooks/useReactHookForm';

import * as SC from './Input.styled';

interface InputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'as'> {
  error?: FieldError;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ type, label, required, error, ...props }, ref) => {
  const id = useId();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <SC.InputContainer>
      {label && (
        <SC.Label required={required} htmlFor={id}>
          {label}
        </SC.Label>
      )}
      <SC.InputWrapper>
        <SC.Input
          id={id}
          ref={ref as any}
          type={showPassword ? undefined : type}
          $error={!!error}
          $hasButton={type === 'password'}
          required={required}
          {...props}
        />
        {type === 'password' && (
          <SC.ShowPasswordButton
            tabIndex={-1}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            type="button"
          >
            <SC.PasswordShowImage src={showPassword ? PasswordHideIcon : PasswordShowIcon} />
          </SC.ShowPasswordButton>
        )}
      </SC.InputWrapper>
      {error && <SC.Error data-testid="error-message">{getFieldError(error)}</SC.Error>}
    </SC.InputContainer>
  );
});

export default Input;

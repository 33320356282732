import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CourseCard from 'components/CourseCard';
import HeaderWithSteps from 'components/HeaderWithSteps';
import * as purchaseActions from 'resources/purchase/purchase.actions';
import * as purchaseSelectors from 'resources/purchase/purchase.selectors';

import SignInForm from './components/SingInForm';
import quote from './quote';
import * as SC from './SignIn.styled';

const SignIn = () => {
  const dispatch = useDispatch();
  const productId = useSelector((state) => purchaseSelectors.getInitialProductId(state));
  const product = useSelector((state) => purchaseSelectors.getInitialProductInfo(state));

  useEffect(() => {
    if (productId) {
      dispatch(purchaseActions.getInitialProductInfo(productId)).catch(() => {});
    }
  }, [productId, dispatch]);

  return (
    <>
      <HeaderWithSteps currentStep={1} />

      <SC.StyledPage>
        <SC.SideView>
          <SC.PatternTop />
          <SC.Bottom>
            <SC.Quote>
              <SC.QuoteText>{quote.text}</SC.QuoteText>
              <SC.QuoteAuthor>{quote.author}</SC.QuoteAuthor>
            </SC.Quote>
            <SC.PatternBottom />
          </SC.Bottom>
        </SC.SideView>

        {product.name && <CourseCard absolute courseName={product.name} price={product.regularPrice} />}

        <SC.SignInPart>
          <SC.SignIn>
            <SC.SignInMain>
              <SC.SignInTitle>Sign In</SC.SignInTitle>
              <SignInForm />
            </SC.SignInMain>
            <SC.SignInFotter>
              <SC.ForgotPasswordLink to="/forgot-password">Forgot Password?</SC.ForgotPasswordLink>
              <SC.PrivacyPolicyLink to="/privacy-policy">Privacy Policy</SC.PrivacyPolicyLink>
            </SC.SignInFotter>
          </SC.SignIn>
        </SC.SignInPart>
      </SC.StyledPage>
    </>
  );
};

export default SignIn;

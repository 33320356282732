import styled from 'styled-components';

import { ReactComponent as BackArrowIcon } from 'assets/back-arrow-color.svg';
import { BackLink } from 'styles/commonStyledComponents';

export const BackNavigation = styled(BackLink)`
  display: flex;
  align-items: center;
`;

export const BackNavigationArrow = styled(BackArrowIcon)`
  margin-right: 7px;
`;

export const BackNavigationText = styled.div``;

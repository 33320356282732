import styled from 'styled-components';

import { BackLink } from 'styles/commonStyledComponents';

export const SignUpForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const UserName = styled.div`
  display: flex;

  & > section:first-child {
    margin-right: 15px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

export const Link = styled(BackLink)`
  text-transform: initial;
  margin-bottom: 20px;
`;

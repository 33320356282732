import { apiClient } from 'utils/api';

export const getInitialProductInfo = (productId) => apiClient.get(`/products/${productId}/info`);

export const getProduct = (productId) => apiClient.get(`products/${productId}/`);

export const applyPromoCode = (productId, promoCode) =>
  apiClient.patch(`products/${productId}/apply_discount_code/`, promoCode);

export const removePromoCode = (productId) => apiClient.patch(`products/${productId}/remove_discount_code/`);

export const postCard = (token) => apiClient.post('cards/', { stripe_token: token });

export const postCharge = (productId, purchaseInfo) => apiClient.post(`products/${productId}/charge/`, purchaseInfo);

export const initPaypalOrder = (productId, howDidYouHear) =>
  apiClient.post('paypal_orders/init/', { product: productId, how_did_you_hear: howDidYouHear });

export const completePaypalOrder = (orderId, orderInfo) =>
  apiClient.patch(`paypal_orders/${orderId}/complete/`, orderInfo);

export const cancelPaypalOrder = (orderId, orderInfo) => apiClient.patch(`paypal_orders/${orderId}/cancel/`, orderInfo);

export const generateSubscriptionCheckout = (id) =>
  apiClient.post('stripe_checkout_sessions/start_subscription/', {
    subscription_product: id,
  });

export const getStripePortalUrl = () => apiClient.post('stripe_manage_subscription/');

import React from 'react';
import PropTypes from 'prop-types';

import { priceToString } from 'utils/price';
import regex from 'utils/regex';

import * as SC from './CourseCard.styled';

const CourseCard = ({ courseName, price, absolute }) => {
  const titleWithSubtitle = courseName.match(regex.subtitle);
  return (
    <SC.CourseCard absolute={absolute}>
      <SC.Title>You’re purchasing the course below:</SC.Title>
      <SC.Course>
        <SC.CourseImage />
        <SC.CourseInfo>
          <SC.CourseTitle>
            <SC.CourseMainTitle>{titleWithSubtitle ? titleWithSubtitle[1] : courseName}</SC.CourseMainTitle>
            {titleWithSubtitle && <SC.CourseSubtitle>{titleWithSubtitle[2]}</SC.CourseSubtitle>}
          </SC.CourseTitle>
          <SC.CoursePrice>${priceToString(price)}</SC.CoursePrice>
        </SC.CourseInfo>
      </SC.Course>
    </SC.CourseCard>
  );
};

CourseCard.propTypes = {
  courseName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};

export default CourseCard;

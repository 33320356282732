import { useEffect, useMemo, useState } from 'react';

import Button from 'components/Button';
import ErrorModal from 'components/ErrorModal';
import ErrorText from 'components/ErrorText';
import Input from 'components/Input';
import Select from 'components/Select';
import useModal from 'hooks/useModal';
import { FormError, useForm } from 'hooks/useReactHookForm';
import * as countriesActions from 'resources/countries/countries.actions';
import * as countriesSelectors from 'resources/countries/countries.selectors';
import { useAppDispatch, useAppSelector } from 'resources/hooks';
import { getStripePortalUrl } from 'resources/purchase/purchase.actions';
import * as userActions from 'resources/user/user.actions';
import * as userSelectors from 'resources/user/user.selectors';

import ProfileModal from '../ProfileModal';
import * as SC from './ProfileInfoTab.styled';

interface FormValues extends FormError {
  first_name: string;
  last_name: string;
  country_code: string;
}

const ProfileInfoTab = () => {
  const dispatch = useAppDispatch();
  const [loadingSubscription, setLoadingSubscription] = useState(false);

  const countries = useAppSelector((state) => countriesSelectors.getCountries(state));
  const user = useAppSelector((state) => userSelectors.getMainUserInfo(state));
  const [visibleSuccess, setHiddenSuccess, setVisibleSuccess] = useModal(false);
  const [visibleErrorModal, setHiddenErrorModal, setVisibleErrorModal, textErrorModal] = useModal(false);

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    mapFormErrors,
    globalError,
    register,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      first_name: user.firstName,
      last_name: user.lastName,
      country_code: user.country?.code,
    },
  });

  useEffect(() => {
    if (!countries || !Object.keys(countries).length) {
      dispatch(countriesActions.getCountries())
        .then(() => {
          setValue('country_code', user.country?.code);
        })
        .catch((error) => {
          if (error.status === 500) {
            setVisibleErrorModal(error.data);
          }
        });
    }
  }, [dispatch, setVisibleErrorModal, setValue, countries, user]);

  const onSubmit = handleSubmit(async ({ country_code, ...values }) => {
    const userInfo = {
      ...values,
      country: {
        code: country_code,
        name: countries[country_code],
      },
    };

    try {
      await dispatch(userActions.updateUser(user.id, userInfo));
      setVisibleSuccess();
    } catch (error) {
      mapFormErrors(error);
    }
  });

  const renderCountries = useMemo(() => Object.keys(countries).map((key) => ({ id: key, name: countries[key] })), [
    countries,
  ]);

  return (
    <SC.Container>
      <SC.ProfileInfo onSubmit={onSubmit}>
        <SC.Name>
          <SC.StyledInput>
            <Input
              label="First Name"
              required
              autoComplete="given-name"
              placeholder="John"
              error={errors.first_name}
              {...register('first_name', {
                required: true,
                maxLength: 50,
              })}
            />
          </SC.StyledInput>
          <Input
            label="Last Name"
            required
            autoComplete="family-name"
            placeholder="Doe"
            error={errors.last_name}
            {...register('last_name', {
              required: true,
              maxLength: 50,
            })}
          />
        </SC.Name>
        <Select
          label="Country"
          placeholder="Select Country"
          options={renderCountries}
          {...register('country_code', {
            required: true,
          })}
        />
        {globalError && <ErrorText error={globalError} negativeBottomMargin />}
        <SC.ButtonWrapper>
          <Button title="Update" type="submit" disabled={isSubmitting} />
        </SC.ButtonWrapper>
        <ProfileModal visible={visibleSuccess} onClose={setHiddenSuccess} />
        <ErrorModal visible={visibleErrorModal} onClose={setHiddenErrorModal} text={textErrorModal} />
      </SC.ProfileInfo>
      <SC.ManageSubscription>
        <SC.ManageSubscriptionTitle>Manage Subscription</SC.ManageSubscriptionTitle>
        <SC.ButtonWrapper>
          <Button
            title="Manage"
            type="button"
            disabled={loadingSubscription}
            onClick={() => {
              setLoadingSubscription(true);
              dispatch(getStripePortalUrl())
                .then((url: string) => {
                  window.location.href = url;
                  setLoadingSubscription(false);
                })
                .catch((error: any) => {
                  if (error.status === 500) {
                    setVisibleErrorModal(error.data);
                  }
                  setLoadingSubscription(false);
                });
            }}
          />
        </SC.ButtonWrapper>
      </SC.ManageSubscription>
    </SC.Container>
  );
};

export default ProfileInfoTab;

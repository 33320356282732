import { apiClient } from 'utils/api';

export const verifyToken = ({ token }) => apiClient.post('/token/verify/', { token });

export const getUser = () => apiClient.get('/users/me/');

export const signUp = (userInfo) => apiClient.post('users/register/', userInfo);

export const signIn = (userInfo) => apiClient.post('token/obtain/', userInfo);

export const updateUser = (userId, userInfo) => apiClient.patch(`users/${userId}/`, userInfo);

export const changePassword = (passwordInfo) => apiClient.post('users/password-change/', passwordInfo);

export const changeEmail = (emailInfo) => apiClient.patch('users/change_email/', emailInfo);

export const requestResetPassword = (emailInfo) => apiClient.post('password-reset/', emailInfo);

export const resetPassword = (passwordInfo, uidb64, token) =>
  apiClient.post(`password-reset/confirm/${uidb64}/${token}/`, passwordInfo);

export const fetchToken = ({ temporaryToken }) => apiClient.post('/generate-token/', { token: temporaryToken });

export const confirmEmail = (id, token) => apiClient.post(`users/${id}/validate-user-token/`, { token });

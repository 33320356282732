import React from 'react';

import HeaderWithSteps from 'components/HeaderWithSteps';

import * as SC from './Confirmation.styled';

const ConfirmSubscription = () => {
  return (
    <>
      <HeaderWithSteps currentStep={3} />
      <SC.StyledPage>
        <SC.ThemeSection>
          <SC.PatternTop>
            <SC.PatternTopLeft />
            <SC.PatternTopRight />
          </SC.PatternTop>
          <SC.PatternMain />
          <SC.ConfirmationMessage>
            <SC.Title white>Thank you!</SC.Title>
            <SC.Subtitle white>Thank you for purchasing the Rituals subscription!</SC.Subtitle>
            <SC.Subtitle white>We’ve sent a confirmation email.</SC.Subtitle>
            <SC.Subtitle white>We look forward to having you study with us.</SC.Subtitle>
          </SC.ConfirmationMessage>
          <SC.PatternBottom>
            <SC.PatternBottomLeft />
            <SC.PatternBottomRight />
          </SC.PatternBottom>
        </SC.ThemeSection>

        <SC.MainSection>
          <SC.Main>
            <SC.StepsTitle>Steps to begin your study</SC.StepsTitle>
            <SC.Step>
              <SC.StepNumber>1.</SC.StepNumber>
              <SC.StepText>
                Download the
                <SC.TextBold> Dandapani App </SC.TextBold>
                using the links below to the App Store or Play Store.
              </SC.StepText>
            </SC.Step>
            <SC.Step>
              <SC.StepNumber>2.</SC.StepNumber>
              <SC.StepText>Log into the App using the email address and password that you created.</SC.StepText>
            </SC.Step>
            <SC.Step>
              <SC.StepNumber>3.</SC.StepNumber>
              <SC.StepText>From the home screen of the App, tap Select Course to begin.</SC.StepText>
            </SC.Step>

            <SC.StoreButtons>
              <SC.AppStoreButton
                href={
                  process.env.REACT_APP_APPSTORE_LINK ||
                  'https://apps.apple.com/us/app/dandapani-learn-to-focus/id1445704770?mt=8'
                }
              />
              <SC.GooglePlayButton
                href={
                  process.env.REACT_APP_GOOGLE_PLAY_LINK ||
                  'https://play.google.com/store/apps/details?id=org.dandapani.app'
                }
              />
            </SC.StoreButtons>
          </SC.Main>
        </SC.MainSection>
      </SC.StyledPage>
    </>
  );
};

export default ConfirmSubscription;

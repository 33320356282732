import styled from 'styled-components';

export const PasswordTab = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  margin-top: 27px;
  max-width: 255px;
`;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import * as userActions from 'resources/user/user.actions';

import * as SC from './ActivateAccount.styled';

const ActivateAccount = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [activated, setActivated] = useState('');

  useEffect(() => {
    (async () => {
      const { pk, token } = params;
      try {
        await dispatch(userActions.confirmEmail(pk, token));
        setActivated('yes');
        setTimeout(() => history.push('/'), 5000);
      } catch (error) {
        setActivated('no');
        setTimeout(() => history.push('/'), 3000);
      }
    })();
  }, []);

  if (activated === 'yes') {
    return (
      <SC.StyledPage>
        <SC.Title>Your account has been activated!</SC.Title>
        <SC.Subtitle>You will be redirected in a few seconds.</SC.Subtitle>
      </SC.StyledPage>
    );
  }

  if (activated === 'no') {
    return (
      <SC.StyledPage>
        <SC.Title>Your account hasn't been activated</SC.Title>
        <SC.Subtitle>You will be redirected in a few seconds.</SC.Subtitle>
      </SC.StyledPage>
    );
  }

  return null;
};

export default ActivateAccount;

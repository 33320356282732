import styled from 'styled-components';

import { ReactComponent as BackArrowIcon } from 'assets/back-arrow.svg';
import colors from 'styles/colors';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const PaymentPage = styled.div``;

export const StyledPage = styled.div``;

export const BackNavigation = styled.div`
  background-color: ${colors.backgroundTheme};
  padding: 8px 140px 20px 140px;
  color: ${colors.fontSecondary};

  display: flex;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: ${metrics.mobile}) {
    padding: 8px 32px 20px 32px;
  }
`;

export const BackNavigationImage = styled(BackArrowIcon)`
  margin-right: 7px;
  margin-bottom: 2px;
`;

export const BackNavigationText = styled.div`
  font-size: ${fonts.size.tiny};
  text-transform: uppercase;
  line-height: 24px;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: ${fonts.size.extraSmall};
  }
`;

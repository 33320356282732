import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import PageHeader from 'components/PageHeader';

import * as SC from './styles';

const DeleteData = () => {
  const history = useHistory();
  const lastLocation = useLastLocation();
  const onNavigateBack = useCallback(() => {
    if (!lastLocation) {
      history.push('/sign-in');
    } else {
      history.push(lastLocation.pathname);
    }
  }, [history, lastLocation]);

  const backNavigation = useMemo(() => {
    if (!lastLocation) {
      return 'Sign In';
    }
    if (lastLocation.pathname === '/sign-in') {
      return 'Sign In';
    }
    if (lastLocation.pathname === '/sign-up') {
      return 'Sign Up';
    }
    return 'Sign In';
  }, [lastLocation]);

  return (
    <>
      <PageHeader
        title="Delete Data"
        onNavigateBack={onNavigateBack}
        backNavigationText={`Go back to ${backNavigation}`}
      />
      <SC.StyledPage>
        <SC.Paragraph>
          <SC.Text>
            <SC.BoldText>How to delete my data from the "Dandapani: Learn to Focus" app</SC.BoldText>
          </SC.Text>
          <SC.Text>
            You can request the deletion of your data through our mobile application. Upon submitting a request, we will
            delete all data associated with your account within 30 days.
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Steps to delete your account</SC.BoldText>
          </SC.Text>
          <SC.Text>
            <ol>
              <li>Download or open the Dandapani mobile application</li>
              <li>Log in to your account</li>
              <li>Tap the Profile button in the top right corner of the main menu</li>
              <li>Locate the "Delete my account" button</li>
              <li>Tap the button and confirm the request</li>
            </ol>
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Warning:</SC.BoldText> This process is irreversible; once deleted, your data cannot be
            restored.
          </SC.Text>
        </SC.Paragraph>
      </SC.StyledPage>
    </>
  );
};

export default DeleteData;

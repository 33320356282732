import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import Modal from '../Modal';
import * as SC from './LegacyUserModal.styled';

const LegacyUserModal = ({ visible, onClose, onNavigateToSignUp }) => {
  const onCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal visible={visible}>
      <SC.Close onClick={onCloseModal} />
      <SC.Text>
        Thank you for being a Legacy student. We need you to sign up with your
        <SC.TextBold>{' prior course email address '}</SC.TextBold>
        for access to all of your courses.
      </SC.Text>
      <SC.ButtonWrapper>
        <Button title="Sign Up" onClick={onNavigateToSignUp} />
      </SC.ButtonWrapper>
    </Modal>
  );
};

LegacyUserModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onNavigateToSignUp: PropTypes.func.isRequired,
};

LegacyUserModal.defaultProps = {
  visible: false,
};

export default LegacyUserModal;

import React from 'react';
import ReactDOM from 'react-dom';

import * as SC from './Loader.styled';

const Loader = ({ visible }) =>
  visible &&
  ReactDOM.createPortal(
    <SC.LoaderContainer>
      <SC.LoaderWrapper>
        <SC.Loader />
      </SC.LoaderWrapper>
    </SC.LoaderContainer>,
    document.getElementById('root')
  );

export default Loader;

class ApiError extends Error {
  data;

  status;

  constructor(data, status) {
    super(data);

    this.name = this.constructor.name;

    this.data = data;
    this.status = status;
  }
}

export default ApiError;

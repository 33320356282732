import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CourseDescription from 'components/CourseDescription';
import ErrorModal from 'components/ErrorModal';
import Loader from 'components/Loader';
import useModal from 'hooks/useModal';
import * as productActions from 'resources/product/product.actions';
import * as productSelectors from 'resources/product/product.selectors';
import * as userSelectors from 'resources/user/user.selectors';

import ProductCard from './components/ProductCard';
import * as SC from './ProductSelection.styled';

const ProductSelection = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedType, setSelectedType] = useState(''); // there are cases when we have the same products in purchased and are in available products
  const [isLoaded, setIsLoaded] = useState(false);
  const user = useSelector((state) => userSelectors.getMainUserInfo(state));
  const purchasedProducts = useSelector((state) => productSelectors.getPurchasedProducts(state));
  const availableProducts = useSelector((state) => productSelectors.getAvailableProducts(state));

  const [visibleErrorModal, setHiddenErrorModal, setVisibleErrorModal, textErrorModal] = useModal(false);

  useEffect(() => {
    (async () => {
      try {
        const [purchased, available] = await Promise.all([
          dispatch(productActions.getPurchasedProducts()),
          dispatch(productActions.getAvailableProducts()),
        ]);

        if (purchased.length) {
          setSelectedProduct(purchased[0]);
          setSelectedType('purchased');
        } else if (available.length) {
          setSelectedProduct(available[0]);
          setSelectedType('available');
        }

        setIsLoaded(true);
      } catch (error) {
        setVisibleErrorModal(error.data);
      }
    })();
  }, []);

  const onCardSelect = useCallback((product, type) => {
    setSelectedProduct(product);
    setSelectedType(type);
  }, []);

  const onBuyProduct = useCallback(
    (product) => {
      history.push(`/payment/${product.id}`);
    },
    [history]
  );

  const getGreetingText = useCallback(() => {
    if (!availableProducts.length) {
      return [
        'Thank you for purchasing lifetime access to all of our available courses.',
        'There are no further courses for you to purchase at this time. We will notify you as we release any new courses.',
      ];
    }

    return [
      'These are the courses by Dandapani that are available for you to purchase. Select a course option and proceed to payment. Please note that our website (dandapani.org) and the Dandapani app are the only authorize sellers of these courses (please do not buy them anywhere else as they are scams).',
    ];
  }, [availableProducts]);

  if (!isLoaded) {
    return <Loader visible />;
  }

  return (
    <>
      <SC.StyledPage>
        <SC.Greeting>
          <SC.GreetingTitle>Hi{user.firstName ? ` ${user.firstName}` : ''}!</SC.GreetingTitle>
          {getGreetingText().map((text) => (
            <SC.GreetingSubtitle key={text}>{text}</SC.GreetingSubtitle>
          ))}
        </SC.Greeting>

        <SC.ProductList>
          {purchasedProducts.map((purchasedProduct) => (
            <ProductCard
              key={purchasedProduct.id}
              productId={purchasedProduct.id}
              onSelect={() => onCardSelect(purchasedProduct, 'purchased')}
              selected={selectedProduct.id === purchasedProduct.id && selectedType === 'purchased'}
              name={purchasedProduct.name}
              accessPeriod={purchasedProduct.access_period}
              lastDay={purchasedProduct.last_active_day}
            />
          ))}

          {availableProducts.map((availableProduct) => (
            <ProductCard
              key={availableProduct.id}
              productId={availableProduct.id}
              onSelect={() => onCardSelect(availableProduct, 'available')}
              selected={selectedProduct.id === availableProduct.id && selectedType === 'available'}
              name={availableProduct.name}
              accessPeriod={availableProduct.access_period}
              price={{
                previous: availableProduct.regular_price,
                current: availableProduct.full_price,
              }}
              onBuy={() => onBuyProduct(availableProduct)}
            />
          ))}
        </SC.ProductList>

        <SC.ProductInfoBelow>
          <SC.ProductInfoBelowText>Product Info Below</SC.ProductInfoBelowText>
          <SC.ProductInfoBelowIcon />
        </SC.ProductInfoBelow>
      </SC.StyledPage>

      <CourseDescription product={selectedProduct} />

      <ErrorModal visible={visibleErrorModal} onClose={setHiddenErrorModal} text={textErrorModal} />
    </>
  );
};

export default ProductSelection;

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import Button from 'components/Button';
import metrics from 'styles/metrics';
import { PRODUCTS } from 'utils/enums';
import { priceToString } from 'utils/price';

import { useMedia } from '../../../../hooks/useMedia';
import * as SC from './ProductCard.styled';

const IMAGES = {
  [PRODUCTS.UF_LIFETIME]: require('assets/UF-course.jpg'),
  [PRODUCTS.IM_LIFETIME_UPGRADE]: require('assets/IM-course.jpg'),
  [PRODUCTS.IM_LIFETIME]: require('assets/2-course-bundle.jpg'),
  [PRODUCTS.EA1]: require('assets/EA1-course.jpg'),
  [PRODUCTS.BUNDLE_3]: require('assets/3-course-bundle.jpg'),
};

const ProductCard = ({ productId, selected, onSelect, onBuy, name, accessPeriod, lastDay, price }) => {
  const isMobile = useMedia([`(max-width: ${metrics.mobile})`], [true], false);

  const getAccessPeriod = useCallback(() => {
    if (accessPeriod) {
      return `${accessPeriod} ${accessPeriod > 1 ? 'Months' : 'Month'} Access`;
    }
    return 'Lifetime access';
  }, [accessPeriod, lastDay]);

  const productImage = IMAGES[productId];

  return (
    <SC.ProductCard onClick={onSelect} selected={selected}>
      <SC.ProductImageContainer withPrice={price}>
        {!productImage ? <SC.ProductLogo /> : <SC.ProductImage src={productImage} />}
      </SC.ProductImageContainer>
      <SC.ProductInfoContainer>
        <SC.ProductInfoWrapper>
          <SC.ProductInfo>
            <SC.ProductNameWrapper>
              <SC.ProductName>{name}</SC.ProductName>
            </SC.ProductNameWrapper>
            <SC.ProductAccess>{getAccessPeriod()}</SC.ProductAccess>
          </SC.ProductInfo>
          {price ? (
            <SC.PriceContainer>
              {price.previous && <SC.PreviousPrice>${priceToString(price.previous)}</SC.PreviousPrice>}
              <SC.CurrentPrice>${priceToString(price.current)}</SC.CurrentPrice>
              {!isMobile && <Button onClick={onBuy} title="Buy" small data-testid="buy-product" />}
            </SC.PriceContainer>
          ) : (
            <SC.ProductStatus>
              <SC.Status>Purchased</SC.Status>
              <SC.ProductLastDay>
                {lastDay ? `till ${format(new Date(lastDay), 'dd MMM, yyyy')}` : ''}
              </SC.ProductLastDay>
            </SC.ProductStatus>
          )}
        </SC.ProductInfoWrapper>
        {isMobile && price && (
          <SC.MobileButtonWrapper>
            <Button onClick={onBuy} title="Buy" small />
          </SC.MobileButtonWrapper>
        )}
      </SC.ProductInfoContainer>
    </SC.ProductCard>
  );
};

ProductCard.propTypes = {
  productId: PropTypes.number.isRequired,
  selected: PropTypes.bool,
  name: PropTypes.string,
  accessPeriod: PropTypes.number,
  price: PropTypes.shape({
    previous: PropTypes.number,
    current: PropTypes.number,
  }),
  onSelect: PropTypes.func.isRequired,
  onBuy: PropTypes.func,
  lastDay: PropTypes.string,
};

ProductCard.defaultProps = {
  selected: false,
  name: '',
  accessPeriod: null,
  price: null,
  onBuy: null,
  lastDay: '',
};

export default ProductCard;

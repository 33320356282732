import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as userActions from 'resources/user/user.actions';
import * as userSelectors from 'resources/user/user.selectors';

import * as SC from './Header.styled';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => userSelectors.getMainUserInfo(state));

  const onLogout = useCallback(() => {
    dispatch(userActions.signOut());
    history.push('/sign-in');
  }, [dispatch, history]);

  const onProfileClick = useCallback(() => {
    history.push('/profile');
  }, [history]);

  return (
    <SC.Header>
      <SC.LogoWrapper>
        <SC.Logo />
        <SC.PrimaryText>Dandapani</SC.PrimaryText>
        <SC.SecondaryText>Spiritual tools for life</SC.SecondaryText>
      </SC.LogoWrapper>
      {user.authenticated && (
        <SC.ProfileWrapper>
          <SC.Profile onClick={onProfileClick}>
            <SC.ProfileImage />
            <SC.UserName>
              {user.firstName} {user.lastName}
            </SC.UserName>
          </SC.Profile>
          <SC.LogOutText onClick={onLogout}>Log Out</SC.LogOutText>
        </SC.ProfileWrapper>
      )}
    </SC.Header>
  );
};

export default Header;

import React, { useState } from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';

import ErrorText from 'components/ErrorText';
import Input from 'components/Input';
import stripeStyles from 'styles/stripe';

import { PaymentFormValues } from '../../types';
import * as SC from './PayByCardForm.styled';

interface Props {
  register: UseFormRegister<PaymentFormValues>;
  errors: FormState<PaymentFormValues>['errors'];
}

const PayByCardForm = ({ register, errors }: Props) => {
  const [cardError, setCardError] = useState('');
  const [cvcError, setCvcError] = useState('');
  const [expiryError, setExpiryError] = useState('');

  return (
    <SC.PayByCardForm>
      <Input
        label="How did you hear about this course?"
        placeholder="Internet"
        required
        {...register('source', {
          required: true,
          maxLength: 50,
        })}
        error={errors.source}
      />

      <SC.StripeFieldWrapper>
        <SC.StripeFieldLabel>Credit Card Number *</SC.StripeFieldLabel>
        <SC.StripeNumberElement
          options={{
            placeholder: '0000 0000 0000 0000',
            style: stripeStyles,
          }}
          onChange={(e) => setCardError(e.error?.message || '')}
        />
        {cardError && <ErrorText error={cardError} absolutePosition />}
      </SC.StripeFieldWrapper>

      <SC.CardInfo>
        <SC.StripeFieldWrapper halfField>
          <SC.StripeFieldLabel>Expiration *</SC.StripeFieldLabel>
          <SC.StripeExpiry
            options={{
              style: stripeStyles,
            }}
            onChange={(e) => setExpiryError(e.error?.message || '')}
          />
          {expiryError && <ErrorText error={expiryError} absolutePosition />}
        </SC.StripeFieldWrapper>

        <SC.StripeFieldWrapper halfField>
          <SC.StripeFieldLabel>CVV *</SC.StripeFieldLabel>
          <SC.StripeCVC
            options={{
              placeholder: 'CVV',
              style: stripeStyles,
            }}
            onChange={(e) => setCvcError(e.error?.message || '')}
          />
          {cvcError && <ErrorText error={cvcError} absolutePosition />}
        </SC.StripeFieldWrapper>
      </SC.CardInfo>

      <Input
        label="ZIP Code"
        placeholder="90210"
        required
        {...register('zip_code', {
          required: true,
          maxLength: 20,
        })}
        error={errors.zip_code}
      />
    </SC.PayByCardForm>
  );
};

export default React.memo(PayByCardForm);

import React, { useEffect } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ErrorModal from 'components/ErrorModal';
import useModal from 'hooks/useModal';
import * as purchaseActions from 'resources/purchase/purchase.actions';
import stringConstants from 'utils/stringConstants';

import * as SC from './CancelPaypalOrder.styled';

const CancelPaypalOrder = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const [visibleErrorModal, setHiddenErrorModal, setVisibleErrorModal, textErrorModal] = useModal(false);

  useEffect(() => {
    (async () => {
      const order = qs.parse(location.search, { ignoreQueryPrefix: true });
      try {
        await dispatch(purchaseActions.cancelPaypalOrder(params.orderId, order));
        history.push('/');
      } catch (error) {
        if (error.data instanceof Object) {
          setVisibleErrorModal(stringConstants.serverError);
        } else {
          setVisibleErrorModal(error.data);
        }
      }
    })();
  }, [location, params, dispatch, history, setVisibleErrorModal]);

  return (
    <SC.StyledPage>
      <SC.Title>PayPal rejected your order.</SC.Title>
      <SC.Subtitle>You will be redirected soon to plans page.</SC.Subtitle>

      <ErrorModal visible={visibleErrorModal} onClose={setHiddenErrorModal} text={textErrorModal} />
    </SC.StyledPage>
  );
};

export default CancelPaypalOrder;

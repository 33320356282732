import axios from 'axios';

import ApiError from './api.error';

const axiosInstance = axios.create();
// Do not throw errors on 'bad' server response codes
axiosInstance.interceptors.response.use(
  (axiosConfig) => axiosConfig,
  (error) => error.response
);

const nodeAxiosInstance = axios.create();
// Do not throw errors on 'bad' server response codes
nodeAxiosInstance.interceptors.response.use(
  (axiosConfig) => axiosConfig,
  (error) => error.response
);

const serverError = ['Server returned an error. Please, contact the support team.'];
const connectionError = ['Connection error. Please, check your Internet connection.'];

const throwApiError = ({ data = {}, status = 500 }) => {
  throw new ApiError(data, status);
};

const httpRequest = (axiosClient, path, method, getHeaders) => async (url, data) => {
  let urlWithSlash = url;

  if (urlWithSlash[0] !== '/') {
    urlWithSlash = `/${urlWithSlash}`;
  }
  const options = {
    method,
    url: `${path}${urlWithSlash}`,
    headers: getHeaders(),
  };

  if (data) {
    if (data.blob) {
      options.responseType = 'blob';
    }

    if (method === 'get') {
      options.params = data;
    } else {
      options.data = data;
    }
  }

  const axiosResponse = await axiosClient(options);

  if (!axiosResponse) {
    throwApiError({
      data: connectionError,
      status: 500,
    });
    return null;
  }

  const response = {};
  response.data = axiosResponse.data || {};
  response.status = axiosResponse.status || 500;

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  if (response.status === 400) {
    throwApiError(response);
  }

  response.data = axiosResponse.data.errors || serverError;
  throwApiError(response);
  return null;
};

const getHeaders = () => () => {
  const token = window.localStorage.getItem('token');
  return token ? { Authorization: `JWT ${token}` } : null;
};

const path = process.env.REACT_APP_API_PATH;

export const apiClient = {
  get: httpRequest(axiosInstance, path, 'get', getHeaders()),
  post: httpRequest(axiosInstance, path, 'post', getHeaders()),
  put: httpRequest(axiosInstance, path, 'put', getHeaders()),
  patch: httpRequest(axiosInstance, path, 'patch', getHeaders()),
  delete: httpRequest(axiosInstance, path, 'delete', getHeaders()),
};

const colors = {
  primary: '#000000',
  secondary: '#FFFFFF',

  fontPrimary: '#000000',
  fontSecondary: '#FFFFFF',
  fontInactive: 'rgba(0, 0, 0, 0.33)',

  backgroundTheme: '#324755',
  backgroundStepsHeader: '#abb8c3',
  backgroundPage: '#FFFFFF',
  backgroundButton: '#000000',
  backgroundDisabled: '#D0CDCD',
  backgroundMenuHover: 'rgba(10, 95, 188, 0.07)',

  darkTheme: '#0B0A26',

  border: 'rgba(0, 0, 0, 0.31)',
  borderFocused: '#0A5FBC',
  line: 'rgba(0, 0, 0, 0.06)',
  link: '#0A5FBC',
  linkHover: '#1A194B',
  error: '#CD2028',
};

export default colors;

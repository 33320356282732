import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import metrics from 'styles/metrics';

import { useMedia } from '../../hooks/useMedia';
import CourseInfo from './components/CourseInfo';
import ExtraInfo from './components/ExtraInfo';
import Feedback from './components/Feedback';
import FreeFeatures from './components/FreeFeatures';
import PackageContent from './components/PackageContent';
import * as SC from './CourseDescription.styled';

const CourseDescription = ({ product }) => {
  const pointsList = useMemo(
    () => [
      { id: 0, title: 'Course Description', content: <CourseInfo product={product} /> },
      { id: 1, title: 'What’s in Package', content: <PackageContent product={product} /> },
      { id: 2, title: 'Free In-App Features', content: <FreeFeatures /> },
      { id: 3, title: 'Customers Feedback', content: <Feedback /> },
      { id: 4, title: 'Extra Information', content: <ExtraInfo product={product} /> },
    ],
    [product]
  );

  const [currentPoint, setCurrentPoint] = useState(pointsList[0].id);
  const isMobile = useMedia([`(max-width: ${metrics.largeTablet})`], [true], false);

  const onPointSelect = useCallback((pointId) => {
    setCurrentPoint(pointId);
  }, []);

  return (
    <SC.CourseDescription>
      <SC.DescriptionSection>{pointsList[currentPoint].content}</SC.DescriptionSection>

      <SC.PointsSection>
        {!isMobile && <SC.Pattern />}
        <SC.PointsList>
          {pointsList.map((point) => (
            <SC.Point key={point.id} selected={currentPoint === point.id} onClick={() => onPointSelect(point.id)}>
              <SC.PointText>{point.title}</SC.PointText>
              <SC.PointIndicator />
            </SC.Point>
          ))}
        </SC.PointsList>
      </SC.PointsSection>
    </SC.CourseDescription>
  );
};

CourseDescription.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    promo_description: PropTypes.string,
  }),
};

export default CourseDescription;

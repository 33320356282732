import { useCallback } from 'react';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Input from 'components/Input';
import useModal from 'hooks/useModal';
import { FormError, useForm } from 'hooks/useReactHookForm';
import * as userActions from 'resources/user/user.actions';

import ProfileModal from '../ProfileModal';
import * as SC from './PasswordTab.styled';

interface FormValues extends FormError {
  password: string;
  password_new: string;
  retype_password_new: string;
}

const PasswordTab = () => {
  const [visibleSuccess, setHiddenSuccess, setVisibleSuccess] = useModal(false);

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    globalError,
    getValues,
    mapFormErrors,
    register,
  } = useForm<FormValues>();

  const onSubmit = handleSubmit(async (values) => {
    try {
      await userActions.changePassword(values);
      setVisibleSuccess();
    } catch (error) {
      mapFormErrors(error);
    }
  });

  const validateRetypePassword = useCallback(
    (value: string) => {
      if (value !== getValues().password_new) {
        return "Passwords don't match.";
      }
    },
    [getValues]
  );

  return (
    <SC.PasswordTab onSubmit={onSubmit}>
      <Input
        label="Current Password"
        type="password"
        required
        autoComplete="current-password"
        placeholder="Enter Current Password"
        error={errors.password}
        {...register('password', {
          required: true,
          maxLength: 127,
        })}
      />
      <Input
        label="New Password"
        type="password"
        autoComplete="new-password"
        placeholder="Enter New Password"
        error={errors.password_new}
        {...register('password_new', {
          required: true,
          maxLength: 127,
        })}
      />
      <Input
        label="Confirm New Password"
        type="password"
        autoComplete="new-password"
        placeholder="Retype New Password"
        error={errors.retype_password_new}
        {...register('retype_password_new', {
          required: true,
          maxLength: 127,
          validate: validateRetypePassword,
        })}
      />

      {globalError && <ErrorText error={globalError} negativeBottomMargin />}
      <SC.ButtonWrapper>
        <Button title="Change Password" type="submit" disabled={isSubmitting} />
      </SC.ButtonWrapper>
      <ProfileModal visible={visibleSuccess} onClose={setHiddenSuccess} />
    </SC.PasswordTab>
  );
};

export default PasswordTab;

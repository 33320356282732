import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import PageHeader from 'components/PageHeader';
import TabView from 'components/TabView';

import EmailTab from './components/EmailTab';
import PasswordTab from './components/PasswordTab';
import ProfileInfoTab from './components/ProfileInfoTab';
import * as SC from './Profile.styled';

const profileTabs = [
  { id: 1, title: 'Profile Information', renderContent: <ProfileInfoTab /> },
  { id: 2, title: 'Change Password', renderContent: <PasswordTab /> },
  { id: 3, title: 'Change Email', renderContent: <EmailTab /> },
];

const Profile = () => {
  const history = useHistory();
  const lastLocation = useLastLocation();
  const [selectedTab, setSelectedTab] = useState(1);

  const onTabSelect = useCallback((tabId) => {
    setSelectedTab(tabId);
  }, []);

  const onNavigateBack = useCallback(() => {
    if (!lastLocation) {
      history.push('/');
    } else if (lastLocation.pathname.includes('/payment')) {
      history.push(lastLocation.pathname);
    } else {
      history.push('/');
    }
  }, [history, lastLocation]);

  const backNavigation = useMemo(() => {
    if (!lastLocation) {
      return 'Product selection';
    }
    if (lastLocation.pathname === '/') {
      return 'Product selection';
    }
    if (lastLocation.pathname.includes('/payment')) {
      return 'Payment';
    }
    return 'Product selection';
  }, [lastLocation]);

  return (
    <>
      <PageHeader title="Account" onNavigateBack={onNavigateBack} backNavigationText={`Go back to ${backNavigation}`} />

      <SC.StyledPage>
        <TabView selectedTab={selectedTab} tabs={profileTabs} onTabSelect={onTabSelect} />
      </SC.StyledPage>
    </>
  );
};

export default Profile;

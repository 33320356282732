import React, { useCallback, useState } from 'react';

import metrics from 'styles/metrics';

import { useMedia } from '../../hooks/useMedia';
import * as SC from './LegacyUserTooltip.styled';

const tooltipText = `Students who previously purchased the 12-week Introduction to Meditation course - 
  please use your prior course email address to sign up in the app in order to get access to your previous courses.`;

const LegacyUserTooltip = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const isMobile = useMedia([`(max-width: ${metrics.largeTablet})`], [true], false);

  const onToggleTooltip = useCallback(() => {
    if (!isMobile) {
      setShowTooltip(!showTooltip);
    }
  }, [showTooltip, isMobile]);

  const onToggleTooltipMobile = useCallback(() => {
    if (isMobile) {
      setShowTooltip(!showTooltip);
    }
  }, [showTooltip, isMobile]);

  return (
    <SC.LegacyUserWrapper>
      <SC.LegacyUser onMouseEnter={onToggleTooltip} onMouseLeave={onToggleTooltip} onClick={onToggleTooltipMobile}>
        {showTooltip && !isMobile && <SC.Tooltip>{tooltipText}</SC.Tooltip>}
        {showTooltip && isMobile && (
          <>
            <SC.TooltipMobile />
            <SC.TooltipMobileContent>
              <SC.TooltipMobileClose onClick={onToggleTooltipMobile} />
              <SC.TooltipMobileText>{tooltipText}</SC.TooltipMobileText>
            </SC.TooltipMobileContent>
          </>
        )}
        <SC.InfoIcon />
        <SC.LegacyUserLink>Are you a previous student of Introduction to Meditation?</SC.LegacyUserLink>
      </SC.LegacyUser>
    </SC.LegacyUserWrapper>
  );
};

export default LegacyUserTooltip;

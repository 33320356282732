import styled from 'styled-components';

import { ReactComponent as PatternBottomImage } from 'assets/pattern-sign-in-bottom.svg';
import { ReactComponent as PatternTopImage } from 'assets/pattern-sign-in-top.svg';
import colors from 'styles/colors';
import { Link } from 'styles/commonStyledComponents';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const StyledPage = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;

  @media only screen and (max-width: ${metrics.mobile}) {
    flex-direction: column;
  }
`;

export const SideView = styled.div`
  background-color: ${(props) => props.backgroundColor || colors.backgroundTheme};
  display: flex;
  width: 50%;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: ${metrics.mobile}) {
    display: none;
  }
`;

export const Hero = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 32px;
`;

export const MobileHero = styled.div`
  display: none;
  flex-direction: column;
  overflow: hidden;

  @media only screen and (max-width: ${metrics.mobile}) {
    display: flex;
  }
`;

export const HeroTitle = styled.h1`
  text-align: center;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 32px;
  padding: 0 16px;

  @media (max-width: ${metrics.tablet}) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0;
  }

  @media (max-width: ${metrics.mobile}) {
    margin-bottom: 0;
  }
`;

export const HeroImage = styled.img`
  object-fit: cover;
  object-position: center center;
  height: 300px;
  width: 100%;

  @media (max-width: 1024px) {
    height: 180px;
  }

  @media (max-width: ${metrics.mobile}) {
    height: 200px;
  }
`;

export const UFDescription = styled.div`
  max-width: 600px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  flex: 1;
  color: #fff;
  font-weight: 500;
`;

export const MobileUFDescription = styled.div`
  padding: 20px;
  margin: 0 auto 24px;
  flex: 1;
  background-color: #1b1c20;
  color: #fff;
  font-weight: 500;
  display: none;

  @media (max-width: ${metrics.mobile}) {
    display: block;
  }
`;

export const UfDescription = styled.p`
  text-align: center;
  font-weight: 500;
  margin-bottom: 32px;
`;

export const UFChapterList = styled.ul`
  gap: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  padding: 0 0 0 20px;
`;

export const UFQuote = styled.div`
  display: flex;
  justify-content: center;
  color: #fff !important;
  font-weight: 500 !important;
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const Quote = styled.div`
  max-width: 400px;

  @media only screen and (max-width: ${metrics.tablet}) {
    max-width: 300px;
  }
`;

export const QuoteText = styled.p`
  color: ${colors.fontSecondary};
  font-size: ${fonts.size.regular};
  line-height: 23px;
  letter-spacing: 0.05em;
  position: relative;
  white-space: pre-wrap;
  font-weight: 500;

  &:before {
    content: '“';
    font-size: ${fonts.size.title};
    position: absolute;
    top: 0px;
    left: -20px;
  }

  &:after {
    content: '”';
    font-size: ${fonts.size.title};
    position: absolute;
    bottom: -5px;
    right: 0;
  }
`;

export const QuoteAuthor = styled.div`
  color: ${(props) => props.color || 'rgba(255, 255, 255, 0.77)'};
  font-size: ${fonts.size.subtitle};
  font-weight: ${fonts.weight.semiBold};
  line-height: 21px;
  margin-top: 30px;
`;

export const SignUpPart = styled.div`
  width: 50%;
  padding: 36px 0px 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media only screen and (max-width: ${metrics.mobile}) {
    width: 100%;
  }

  @media only screen and (max-width: ${metrics.largeTablet}) {
    padding: 42px 0;
  }
`;

export const SignUp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 514px;
  width: 100%;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    padding: 0 32px;
  }
`;

export const SignUpTitle = styled.div`
  font-size: ${fonts.size.header};
  margin-bottom: 10px;
  margin-top: 40px;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: 26px;
  }
`;

export const PatternBottom = styled(PatternBottomImage)``;

export const PatternTop = styled(PatternTopImage)``;

export const SignUpMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SignUpFotter = styled.div`
  margin-top: 30px;
`;

export const PrivacyPolicyLink = styled(Link)``;

export const Quote2 = styled.div`
  max-width: 400px;

  @media only screen and (max-width: ${metrics.tablet}) {
    max-width: 300px;
  }
`;

export const QuoteText2 = styled.p`
  color: ${colors.fontSecondary};
  font-size: ${fonts.size.regular};
  line-height: 23px;
  letter-spacing: 0.05em;
  position: relative;
  white-space: pre-wrap;
  font-weight: 500;
  text-align: center;

  &:before {
    content: '“';
    font-size: ${fonts.size.title};
    position: absolute;
    top: 0px;
    left: -20px;
  }

  &:after {
    content: '”';
    font-size: ${fonts.size.title};
    position: absolute;
    bottom: -5px;
    right: 0;
  }
`;

export const QuoteAuthor2 = styled.div`
  color: #fff;
  font-size: ${fonts.size.subtitle};
  font-weight: ${fonts.weight.semiBold};
  line-height: 21px;
  margin-top: 30px;
  text-align: center;
`;

export const UFShadow = styled.div`
  width: 100%;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.18);
  margin-bottom: 32px;

  div {
    border: none;
  }
`;

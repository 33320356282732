import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import BackNavigation from 'components/BackNavigation';
import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Input from 'components/Input';
import Loader from 'components/Loader';
import SuccessModal from 'components/SuccessModal';
import useModal from 'hooks/useModal';
import { FormError, useForm } from 'hooks/useReactHookForm';
import * as userActions from 'resources/user/user.actions';

import * as SC from './ForgotPassword.styled';

interface FormValues extends FormError {
  email: string;
}

const ForgotPassword = ({ history }: RouteComponentProps<{ error?: string }>) => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [visibleSuccessModal, setHiddenSuccessModal, setVisibleSuccessModal] = useModal(false);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    mapFormErrors,
    getValues,
    register,
    globalError,
  } = useForm<FormValues>();

  const onSubmit = handleSubmit(async (values) => {
    setIsLoaded(false);

    try {
      await userActions.requestResetPassword(values);
      setIsLoaded(true);
      setVisibleSuccessModal();
    } catch (error) {
      setIsLoaded(true);
      mapFormErrors(error);
    }
  });

  return (
    <SC.StyledPage>
      <SC.BackNavigation>
        <BackNavigation to="/sign-in" title="Go back to Sign In" />
      </SC.BackNavigation>
      <SC.Form onSubmit={onSubmit}>
        <SC.Title>
          {history.location.search.indexOf('error') >= 0 ? 'The link has expired.' : 'Forgot your password?'}
        </SC.Title>
        <SC.Subtitle>
          {history.location.search.indexOf('error') >= 0
            ? 'Sorry, your password reset link has expired. Please enter your email address to get a new one.'
            : 'Please enter your email address.'}
        </SC.Subtitle>
        <Input
          label="Your Email Address"
          type="email"
          placeholder="Email Address"
          error={errors.email}
          autoComplete="email"
          {...register('email', {
            required: true,
            maxLength: 127,
          })}
        />

        {globalError && <ErrorText error={globalError} negativeBottomMargin />}

        <SC.ButtonWrapper>
          <Button title="Send me reset link" type="submit" disabled={isSubmitting} />
        </SC.ButtonWrapper>
      </SC.Form>
      <SuccessModal visible={visibleSuccessModal} onClose={setHiddenSuccessModal}>
        <SC.Text>
          A link to reset your password has just been sent to
          <SC.TextBold>{` ${getValues().email}. `}</SC.TextBold>
          Please check your email and follow the directions to reset your password.
        </SC.Text>
      </SuccessModal>
      <Loader visible={!isLoaded} />
    </SC.StyledPage>
  );
};

export default ForgotPassword;

import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Input from 'components/Input';
import LegacyUserTooltip from 'components/LegacyUserTooltip';
import { FormError, useForm } from 'hooks/useReactHookForm';
import { useAppDispatch, useAppSelector } from 'resources/hooks';
import * as purchaseSelectors from 'resources/purchase/purchase.selectors';
import * as userActions from 'resources/user/user.actions';
import * as userSelectors from 'resources/user/user.selectors';
import { BackLink } from 'styles/commonStyledComponents';

import * as SC from './SignUpForm.styled';

interface FormValues extends FormError {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  retype_password: string;
}

const SignUpForm = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const legacyEmail = useAppSelector((state) => userSelectors.getLegacyUserEmail(state));
  const productId = useAppSelector((state) => purchaseSelectors.getInitialProductId(state));

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    mapFormErrors,
    register,
    globalError,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      email: legacyEmail,
    },
  });

  const onSubmit = handleSubmit(async (userInfo) => {
    try {
      const params = new URLSearchParams(history.location.search);

      await dispatch(userActions.signUp(userInfo));

      if (productId) {
        history.push(`/payment/${productId}`);
      } else if (params.has('rituals')) {
        history.replace(`/subscribe/${params.get('rituals')}`);
      } else {
        history.push('/');
      }
    } catch (error) {
      mapFormErrors(error);
    }
  });

  const validateRetypePassword = useCallback(
    (value: FormValues['retype_password']) => {
      if (value !== getValues('password')) {
        return 'Passwords must match';
      }
    },
    [getValues]
  );

  return (
    <SC.SignUpForm onSubmit={onSubmit}>
      <SC.Link to="/signin">Already registered. Sign in here!</SC.Link>
      <Input
        label="Email Address"
        type="email"
        autoComplete="email"
        error={errors.email}
        placeholder="Email Address"
        {...register('email', {
          required: true,
          maxLength: 127,
        })}
        required
      />
      <SC.UserName>
        <Input
          label="First Name"
          autoComplete="given-name"
          error={errors.first_name}
          placeholder="First Name"
          {...register('first_name', {
            required: true,
            maxLength: 50,
          })}
          required
        />
        <Input
          label="Last Name"
          autoComplete="family-name"
          error={errors.last_name}
          placeholder="Last Name"
          {...register('last_name', {
            required: true,
            maxLength: 50,
          })}
          required
        />
      </SC.UserName>
      <Input
        label="Password"
        type="password"
        autoComplete="new-password"
        error={errors.password}
        placeholder="Password"
        {...register('password', {
          required: true,
          maxLength: 50,
        })}
        required
      />
      <Input
        label="Confirm Password"
        type="password"
        autoComplete="new-password"
        error={errors.retype_password}
        placeholder="Retype Password"
        {...register('retype_password', {
          required: true,
          maxLength: 50,
          validate: validateRetypePassword,
        })}
        required
      />
      {globalError && <ErrorText error={globalError} negativeBottomMargin />}
      <SC.ButtonWrapper>
        <Button title="Sign Up" type="submit" disabled={isSubmitting} />
      </SC.ButtonWrapper>
    </SC.SignUpForm>
  );
};

export default SignUpForm;

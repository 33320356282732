import React, { useId } from 'react';
import { FieldError } from 'react-hook-form';

import DownSelectArrow from 'assets/down-select-arrow.svg';
import { getFieldError } from 'hooks/useReactHookForm';

import * as SC from './Select.styled';

interface IOption {
  id: string | number;
  name: string;
}

interface SelectProps extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'as'> {
  error?: FieldError;
  options: IOption[];
  label: string;
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ label, required, error, options, ...props }, ref) => {
    const id = useId();

    return (
      <SC.InputContainer>
        {label && (
          <SC.Label required={required} htmlFor={id}>
            {label}
          </SC.Label>
        )}
        <SC.InputWrapper>
          <SC.Select id={id} ref={ref as any} $error={!!error} {...props}>
            {options.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </SC.Select>
          <SC.SelectButton aria-hidden>
            <img src={DownSelectArrow} alt="Select" />
          </SC.SelectButton>
        </SC.InputWrapper>
        {error && <SC.Error>{getFieldError(error)}</SC.Error>}
      </SC.InputContainer>
    );
  }
);

export default Select;

import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import metrics from 'styles/metrics';

export const Close = styled(CloseIcon)`
  align-self: flex-end;
  cursor: pointer;
`;

export const Text = styled.div`
  margin: 20px 30px 30px 30px;
  letter-spacing: 0.05em;
  text-align: center;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin: 5px 13px 20px 13px;
  }
`;

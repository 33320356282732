import {
  CLEAR_PURCHASE,
  GET_PRODUCT,
  GET_PRODUCT_INFO,
  GET_STRIPE_PORTAL_URL,
  PAYPAL_COMPLETED,
  PROMO_APPLIED,
  PROMO_REMOVED,
  SET_CHARGE,
  SET_INITIAL_PRODUCT,
} from './purchase.actions';

const initialState = {
  initialProduct: {},
  product: {},
  newProduct: {},
  charge: null,
  paypalMeta: null,
  stripePortalUrl: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_PRODUCT:
      return {
        ...state,
        initialProduct: {
          productId: action.productId,
        },
      };
    case GET_PRODUCT_INFO:
      return {
        ...state,
        initialProduct: {
          productId: action.productInfo.product_id,
          name: action.productInfo.name,
          regularPrice: action.productInfo.full_price,
        },
      };
    case GET_PRODUCT:
      const code = action.productInfo.user_product?.user_product_discount_code?.discount_code?.code;

      return {
        ...state,
        product: action.productInfo,
        newProduct: code ? action.productInfo : state.newProduct,
        regularPrice:
          Number(`${action.productInfo.meta.oldPrice}${action.productInfo.meta.oldPriceCents || '00'}`) ||
          action.productInfo.full_price,
        promoApplied: code,
      };
    case PROMO_APPLIED:
      return {
        ...state,
        newProduct: action.productInfo.product,
        promoApplied: action.productInfo.promoCode,
      };
    case PROMO_REMOVED:
      return {
        ...state,
        newProduct: {},
        promoApplied: null,
      };
    case PAYPAL_COMPLETED:
      return {
        ...state,
        product: {
          id: action.productInfo.id,
          name: action.productInfo.product_name,
          current_price: action.productInfo.current_price,
        },
        paypalMeta: action.paypalMeta,
      };
    case CLEAR_PURCHASE:
      return {
        initialProduct: {},
        product: {},
        newProduct: {},
      };
    case SET_CHARGE:
      return {
        ...state,
        charge: action.charge,
      };
    case GET_STRIPE_PORTAL_URL:
      return {
        ...state,
        stripePortalUrl: action.url,
      };
    default:
      return state;
  }
};

export default reducer;

import styled from 'styled-components';

import AppStore from 'assets/app-store-button.svg';
import { ReactComponent as PatternMainImage } from 'assets/confirmation-main-pattern.svg';
import { ReactComponent as PatternImage } from 'assets/confirmation-pattern.svg';
import GooglePlay from 'assets/google-play-button.svg';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const ConfirmationPage = styled.div``;

export const StyledPage = styled.div`
  display: flex;
  flex-grow: 1;

  @media only screen and (max-width: ${metrics.tablet}) {
    flex-direction: column;
  }
`;

export const ThemeSection = styled.div`
  width: 50%;
  background-color: #121240;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: ${metrics.tablet}) {
    width: 100%;
  }

  @media only screen and (max-width: ${metrics.mobile}) {
    position: relative;
    padding: 26px 45px 40px 45px;
    align-items: center;
  }
`;

export const PatternTop = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${metrics.mobile}) {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
  }
`;

export const PatternTopLeft = styled(PatternImage)`
  @media only screen and (max-width: ${metrics.mobile}) {
    width: 82px;
    height: auto;
  }
`;

export const PatternTopRight = styled(PatternImage)`
  transform: rotate(90deg);

  @media only screen and (max-width: ${metrics.mobile}) {
    width: 82px;
    height: auto;
  }
`;

export const PatternBottom = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${metrics.mobile}) {
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
  }
`;

export const PatternBottomLeft = styled(PatternImage)`
  transform: rotate(270deg);

  @media only screen and (max-width: ${metrics.mobile}) {
    width: 82px;
    height: auto;
  }
`;

export const PatternBottomRight = styled(PatternImage)`
  transform: rotate(180deg);

  @media only screen and (max-width: ${metrics.mobile}) {
    width: 82px;
    height: auto;
  }
`;

export const PatternMain = styled(PatternMainImage)`
  display: none;

  @media only screen and (max-width: ${metrics.mobile}) {
    display: block;
    max-width: 500px;
  }
`;

export const ConfirmationMessage = styled.div`
  padding: 0px 32px;

  @media only screen and (max-width: ${metrics.mobile}) {
    position: absolute;
    top: calc(50% - 65px);
  }
`;

export const Title = styled.div`
  font-size: 36px;
  letter-spacing: -0.02em;
  text-align: center;
  color: ${(props) => props.white && '#FFFFFF'};
  margin-bottom: 25px;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: 26px;
  }
`;

export const Subtitle = styled.div`
  text-align: center;
  color: ${(props) => props.white && '#FFFFFF'};

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: 13px;
    letter-spacing: -0.01em;
  }
`;

export const MainSection = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${metrics.tablet}) {
    width: 100%;
    padding: 62px 32px 91px 32px;
  }
`;

export const Main = styled.div`
  max-width: 450px;
  width: 100%;
`;

export const StepsTitle = styled.div`
  text-align: center;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #000000;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
`;

export const StepNumber = styled.div`
  font-size: 30px;
  line-height: 26px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #000000;
  margin-right: 23px;

  @media only screen and (max-width: ${metrics.tablet}) {
    margin-right: 13px;
  }
`;

export const StepText = styled.div`
  color: rgba(0, 0, 0, 0.8);
`;

export const TextBold = styled.span`
  font-weight: ${fonts.weight.semiBold};
`;

export const StoreButtons = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: center;
  flex-wrap: wrap;
`;

export const AppStoreButton = styled.a.attrs({
  target: '_blank',
})`
  cursor: pointer;
  margin-right: 22px;
  background: url(${AppStore}) no-repeat;
  width: 140px;
  height: 50px;

  @media only screen and (max-width: ${metrics.tablet}) {
    margin-right: 37px;
  }

  @media only screen and (max-width: ${metrics.mobile}) {
    margin-right: 10px;
  }
`;

export const GooglePlayButton = styled.a.attrs({
  target: '_blank',
})`
  cursor: pointer;
  background: url(${GooglePlay}) no-repeat;
  width: 155px;
  height: 50px;
`;

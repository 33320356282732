import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import styled, { css } from 'styled-components';

import colors from 'styles/colors';

// const getInputBorder = ({ error, focused, showError }) => {
//   if (error || showError) {
//     return `1px solid ${colors.error}`;
//   }
//   if (focused) {
//     return `1px solid ${colors.borderFocused}`;
//   }
//   return `1px solid ${colors.border}`;
// };

export const PayByCardForm = styled.div`
  position: relative;
`;

export const CardInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StripeFieldWrapper = styled.div<{ halfField?: boolean }>`
  width: ${(props) => (props.halfField ? '48%' : '100%')};
  margin-bottom: 23px;
`;

export const StripeFieldLabel = styled.label`
  margin-bottom: 13px;
`;

const stripeStyling = css`
  padding: 0px 15px;
  width: 100%;
  border: 1px solid ${colors.border};

  &.StripeElement--focus {
    border: 1px solid ${colors.borderFocused};
  }

  &.StripeElement--invalid {
    border: 1px solid ${colors.error};
  }
`;

export const StripeNumberElement = styled(CardNumberElement)`
  ${stripeStyling}
`;

export const StripeCVC = styled(CardCvcElement)`
  ${stripeStyling}
`;

export const StripeExpiry = styled(CardExpiryElement)`
  ${stripeStyling}
`;

import React from 'react';
import PropTypes from 'prop-types';

import * as SC from '../../CourseDescription.styled';

const ExtraInfo = ({ product }) => {
  return (
    <SC.Content>
      <SC.Title>Extra Information</SC.Title>
      <SC.SubTitle>Please note that :</SC.SubTitle>
      <SC.List>
        {product.meta.description
          .filter((item) => !item.platform || item.platform === 'android')
          .map((item, index) => (
            <SC.Item key={index}>
              <SC.Indicator />
              <SC.ItemText>{item.children.split('• ')[1]}</SC.ItemText>
            </SC.Item>
          ))}
      </SC.List>
    </SC.Content>
  );
};

ExtraInfo.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default ExtraInfo;

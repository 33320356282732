import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import fonts from 'styles/fonts';

export const InputContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;
  position: relative;
  width: 100%;
`;

export const Label = styled.label<{ required?: boolean }>`
  margin-bottom: 13px;

  &:after {
    ${(props) =>
      props.required
        ? css`
            content: ' *';
          `
        : css`
            content: '';
          `};
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Select = styled.select<{ $error?: boolean }>`
  outline: none;
  width: 100%;
  letter-spacing: 0.02em;
  font-weight: ${fonts.weight.medium};
  font-size: ${fonts.size.regular};
  border: ${(props) => (props.$error ? `1px solid ${colors.error}` : `1px solid ${colors.border}`)};
  padding: 12px 15px 13px 15px;

  ::placeholder {
    font-size: ${fonts.size.regular};
    letter-spacing: 0.02em;
    color: ${colors.fontInactive};
    font-weight: ${fonts.weight.regular};
  }

  :focus {
    border: 1px solid ${colors.borderFocused};
  }

  :disabled {
    color: ${colors.fontInactive};
  }
`;

export const SelectButton = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  pointer-events: none;
`;

export const Error = styled.div`
  position: absolute;
  top: 100%;
  color: ${colors.error};
  letter-spacing: 0.05em;
  font-size: ${fonts.size.tiny};
  line-height: 19px;
`;

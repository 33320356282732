import styled from 'styled-components';

import metrics from 'styles/metrics';

export const Container = styled.div`
  margin-bottom: 12.5vh;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin-bottom: 50vh;
  }
`;

export const ProfileInfo = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const StyledInput = styled.div`
  margin-right: 15px;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  margin-top: 27px;
  max-width: 171px;
`;

export const ManageSubscription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`;

export const ManageSubscriptionTitle = styled.h2`
  font-weight: normal;
`;

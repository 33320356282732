import { FormState, UseFormRegister } from 'react-hook-form';

import Input from 'components/Input';
import RadioButton from 'components/RadioButton';

import { PaymentFormValues } from '../../types';
import * as SC from './PayWithPaypalForm.styled';

interface Props {
  register: UseFormRegister<PaymentFormValues>;
  errors: FormState<PaymentFormValues>['errors'];
}

const PayWithPaypalForm = ({ register, errors }: Props) => (
  <SC.PayWithPaypalForm>
    <Input
      label="How did you hear about this course?"
      required
      placeholder="Internet"
      {...register('source', {
        required: true,
        maxLength: 50,
      })}
      error={errors.source}
    />
    <SC.PaypalPayment>
      <SC.PaymentLabel>Payment *</SC.PaymentLabel>
      <SC.Paypal>
        <RadioButton selected />
        <SC.PaypalIcon />
      </SC.Paypal>
      <SC.PaypalCard>
        <SC.CardIcon />
        <SC.PaypalCardText>
          After clicking “Order Now”, you will be redirected to PayPal to complete your purchase securely.
        </SC.PaypalCardText>
      </SC.PaypalCard>
    </SC.PaypalPayment>
  </SC.PayWithPaypalForm>
);

export default PayWithPaypalForm;

import React from 'react';

import * as SC from '../../CourseDescription.styled';

const FreeFeatures = () => (
  <SC.Content>
    <SC.Title>Free In-App Features</SC.Title>
    <SC.SubTitle>here they are :</SC.SubTitle>
    <SC.List>
      <SC.Item>
        <SC.Indicator />
        <SC.ItemText>Video Playlists: Watch videos of Dandapani’s talks, interviews and more.</SC.ItemText>
      </SC.Item>
      <SC.Item>
        <SC.Indicator />
        <SC.ItemText>Audio Library: Listen to conversations on the spiritual path by Dandapani and more.</SC.ItemText>
      </SC.Item>
      <SC.Item>
        <SC.Indicator />
        <SC.ItemText>Daily Quote: Enjoy insightful quotes from Dandapani on the app.</SC.ItemText>
      </SC.Item>
      <SC.Item>
        <SC.Indicator />
        <SC.ItemText>
          Downloadable Journal: For daily reflections and to cultivate gratitude and selflessness.
        </SC.ItemText>
      </SC.Item>
    </SC.List>
  </SC.Content>
);

export default FreeFeatures;

import * as purchaseApi from './purchase.api';

export const SET_INITIAL_PRODUCT = 'SET_INITIAL_PRODUCT';
export const GET_PRODUCT_INFO = 'GET_PRODUCT_INFO';
export const GET_PRODUCT = 'GET_PRODUCT';
export const PROMO_APPLIED = 'PROMO_APPLIED';
export const PROMO_REMOVED = 'PROMO_REMOVED';
export const PAYPAL_COMPLETED = 'PAYPAL_COMPLETED';
export const PAYPAL_CANCELED = 'PAYPAL_CANCELED';
export const CLEAR_PURCHASE = 'CLEAR_PURCHASE';
export const SET_CHARGE = 'SET_CHARGE';
export const GENERATE_STRIPE_PORTAL = 'GENERATE_STRIPE_PORTAL';

export const setInitialProduct = (productId) => (dispatch) => {
  dispatch({ type: SET_INITIAL_PRODUCT, productId });
};

export const getInitialProductInfo = (productId) => async (dispatch) => {
  const productInfo = await purchaseApi.getInitialProductInfo(productId);
  dispatch({ type: GET_PRODUCT_INFO, productInfo });
};

export const getProduct = (productId) => async (dispatch) => {
  const productInfo = await purchaseApi.getProduct(productId);
  dispatch({ type: GET_PRODUCT, productInfo });
};

export const apllyPromoCode = (productId, promoCode) => async (dispatch) => {
  const product = await purchaseApi.applyPromoCode(productId, promoCode);
  dispatch({ type: PROMO_APPLIED, productInfo: { product, promoCode: promoCode.discount_code } });
};

export const removePromoCode = (productId) => async (dispatch) => {
  await purchaseApi.removePromoCode(productId);
  dispatch({ type: PROMO_REMOVED });
};

export const payByCard = (token, purchaseInfo) => async (dispatch) => {
  let cardData = {};

  if (token) {
    cardData = await purchaseApi.postCard(token);
  }

  const charge = await purchaseApi.postCharge(purchaseInfo.productId, {
    card: cardData.id,
    price: purchaseInfo.price,
    how_did_you_hear: purchaseInfo.how_did_you_hear,
    free: purchaseInfo.free,
  });

  dispatch({ type: SET_CHARGE, charge });
};

export const initPaypalOrder = (productId, howDidYouHear) => async () => {
  const orderData = await purchaseApi.initPaypalOrder(productId, howDidYouHear);
  return orderData;
};

export const completePaypalOrder = (orderId, orderInfo) => async (dispatch) => {
  const productInfo = await purchaseApi.completePaypalOrder(orderId, orderInfo);
  dispatch({ type: PAYPAL_COMPLETED, productInfo, paypalMeta: orderInfo });
  return productInfo;
};

export const cancelPaypalOrder = (orderId, orderInfo) => async () => {
  await purchaseApi.cancelPaypalOrder(orderId, orderInfo);
};

export const generateStripePortal = (id) => async (dispatch) => {
  const { url } = await purchaseApi.generateSubscriptionCheckout(id);
  dispatch({ type: GENERATE_STRIPE_PORTAL, url });
  return url;
};

export const GET_STRIPE_PORTAL_URL = 'GET_STRIPE_PORTAL_URL';

export const getStripePortalUrl = () => async (dispatch) => {
  const { url } = await purchaseApi.getStripePortalUrl();
  dispatch({
    type: GET_STRIPE_PORTAL_URL,
    url,
  });
  return url;
};

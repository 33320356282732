import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import PageHeader from 'components/PageHeader';

import * as SC from './PrivacyPolicy.styled';

const PrivacyPolicy = () => {
  const history = useHistory();
  const lastLocation = useLastLocation();
  const onNavigateBack = useCallback(() => {
    if (!lastLocation) {
      history.push('/sign-in');
    } else {
      history.push(lastLocation.pathname);
    }
  }, [history, lastLocation]);

  const backNavigation = useMemo(() => {
    if (!lastLocation) {
      return 'Sign In';
    }
    if (lastLocation.pathname === '/sign-in') {
      return 'Sign In';
    }
    if (lastLocation.pathname === '/sign-up') {
      return 'Sign Up';
    }
    return 'Sign In';
  }, [lastLocation]);

  return (
    <>
      <PageHeader
        title="Privacy Policy"
        onNavigateBack={onNavigateBack}
        backNavigationText={`Go back to ${backNavigation}`}
      />
      <SC.StyledPage>
        <SC.Paragraph>
          <SC.Text>
            <SC.BoldText>Dandapani LLC Privacy Policy</SC.BoldText>
          </SC.Text>
          <SC.Text>
            {`Thank you for choosing to be part of our community at Dandapani LLC. 
            We are committed to protecting your personal information and your right to privacy. 
            If you have any questions or concerns about our policy, or our practices with regards 
            to your personal information, please contact us at `}
            <SC.TextLink href="mailto: privacy@dandapani.org">privacy@dandapani.org.</SC.TextLink>
          </SC.Text>
          <SC.Text>
            {`When you visit our website dandapani.org, mobile application, and use our services, 
            you trust us with your personal information. We take your privacy very seriously. 
            In this privacy notice, we describe our privacy policy. We seek to explain to you 
            in the clearest way possible what information we collect, how we use it and what 
            rights you have in relation to it. We hope you take some time to read through it carefully, 
            as it is important. If there are any terms in this privacy policy that you do not agree with, 
            please discontinue use of our Sites or Apps and our services.`}
          </SC.Text>
          <SC.Text>
            {`This privacy policy applies to all information collected through our website 
            (dandapani.org), mobile application, ("Apps"), and/or any related services, sales, 
            marketing or events (we refer to them collectively in this privacy policy as the "Sites").`}
          </SC.Text>
          <SC.Text>
            {`Please read this privacy policy carefully as it will help you make informed 
            decisions about sharing your personal information with us.`}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>1. What information do we collect?</SC.ParagraphTitle>
          <SC.Text>Personal information you disclose to us.</SC.Text>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {` We collect personal information that you provide to us such as name, address, 
            contact information, passwords and security data, payment information, and social media login data.`}
          </SC.ItalicText>
          <SC.Text>
            {`We collect personal information that you voluntarily provide to us 
            when registering at the Sites or Apps, expressing an interest in obtaining 
            information about us or our products and services, when participating in 
            activities on the Sites or Apps (such as posting messages in our 
            online forums) or otherwise contacting us.`}
          </SC.Text>
          <SC.Text>
            {`The personal information that we collect depends on the context of 
            your interactions with us and the Sites or Apps, the choices you make 
            and the products and features you use. The personal information 
            we collect can include the following:`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Name and Contact Data.</SC.BoldText>
            {` We collect your first and last name, email address, 
            postal address, phone number, and other similar contact data.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Credentials.</SC.BoldText>
            {` We collect passwords, password hints, and similar security 
            information used for authentication and account access.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Payment Data.</SC.BoldText>
            {` We collect data necessary to process your payment if you make purchases, 
            such as your payment instrument number (such as a credit card number), 
            and the security code associated with your payment instrument. 
            All payment data is stored by our payment processor and you should review 
            its privacy policies and contact the payment processor directly to respond to your questions.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Social Media Login Data.</SC.BoldText>
            {` We provide you with the option to register using social media account details, 
            like your Facebook, Twitter or other social media account. 
            If you choose to register in this way, we will collect the Information 
            described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS " below.`}
          </SC.Text>
          <SC.Text>
            {`All personal information that you provide to us must be true, 
            complete and accurate, and you must notify us of any changes to such personal information.`}
          </SC.Text>
          <SC.Text>Information automatically collected</SC.Text>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {` Some information – such as IP address and/or browser and device characteristics – 
            is collected automatically when you visit our Sites or Apps.`}
          </SC.ItalicText>
          <SC.Text>
            {`We automatically collect certain information when you visit, use or navigate the Sites or Apps. 
            This information does not reveal your specific identity (like your name or contact information) 
            but may include device and usage information, such as your IP address, 
            browser and device characteristics, operating system, language preferences, referring URLs, 
            device name, country, location, information about how and when you use our Sites or 
            Apps and other technical information. This information is primarily needed to maintain 
            the security and operation of our Sites or Apps, and for our internal analytics and reporting purposes.`}
          </SC.Text>
          <SC.Text>
            {`Like many businesses, we also collect information 
            through cookies and similar technologies.`}
          </SC.Text>
          <SC.Text>Information collected through our Apps</SC.Text>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {` We may collect information regarding your geo-location, 
            mobile device, push notifications, when you use our apps.`}
          </SC.ItalicText>
          <SC.Text>If you use our Apps, we may also collect the following information:</SC.Text>
          <SC.Text>
            <SC.BoldText>Geo-Location Information.</SC.BoldText>
            {` We may request access or permission to and track location-based information from 
            your mobile device, either continuously or while you are using our mobile application, 
            to provide location-based services. If you wish to change our access or permissions, 
            you may do so in your device’s settings.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Mobile Device Access.</SC.BoldText>
            {`We may request access or permission to certain features from your mobile device, 
            including your mobile device’s bluetooth, calendar, camera, contacts, microphone, 
            reminders, sensors, storage, and other features. If you wish to change our access or 
            permissions, you may do so in your device’s settings.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Mobile Device Data.</SC.BoldText>
            {` We may automatically collect device information (such as your mobile device ID, 
              model and manufacturer), operating system, version information and IP address.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Push Notifications.</SC.BoldText>
            {` We may request to send you push notifications regarding your account or the mobile application. 
            If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.`}
          </SC.Text>
          <SC.Text>Information collected from other sources</SC.Text>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {` We may collect limited data from public databases, marketing partners, 
            social media platforms, and other outside sources.`}
          </SC.ItalicText>
          <SC.Text>
            {`We may obtain information about you from other sources, such as public databases, 
            joint marketing partners, social media platforms (such as Facebook), as well as 
            from other third parties. Examples of the information we receive from other 
            sources include: social media profile information (your name, gender, birthday, 
            email, current city, state and country, user identification numbers for your contacts, 
            profile picture URL and any other information that you choose to make public); marketing 
            leads and search results and links, including paid listings (such as sponsored links).`}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>2. How do we use your information?</SC.ParagraphTitle>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {` We process your information for purposes based on legitimate business interests, 
            the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.`}
          </SC.ItalicText>
          <SC.Text>
            {`We use personal information collected via our Sites or Apps for a variety of 
            business purposes described below. We process your personal information for 
            these purposes in reliance on our legitimate business interests, in order to enter 
            into or perform a contract with you, with your consent, and/or for compliance with our 
            legal obligations. We indicate the specific processing grounds we rely on 
            next to each purpose listed below.`}
          </SC.Text>
          <SC.Text>We use the information we collect or receive:</SC.Text>
          <SC.Text>
            <SC.BoldText>To facilitate account creation and logon process.</SC.BoldText>
            {` If you choose to link your account with us to a third party account 
            *(such as your Google or Facebook account), we use the information you allowed 
            us to collect from those third parties to facilitate account creation and logon process. 
            See the section below headed " HOW DO WE HANDLE YOUR SOCIAL LOGINS " for further information.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>To send you marketing and promotional communications.</SC.BoldText>
            {` We and/or our third party marketing partners may use the personal information you 
            send to us for our marketing purposes, if this is in accordance with your marketing preferences. 
            You can opt-out of our marketing emails at any time (see the " WHAT ARE YOUR PRIVACY RIGHTS " below).`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>To send administrative information to you.</SC.BoldText>
            {` We may use your personal information to send you product, service 
            and new feature information and/or information about changes to our terms, conditions, and policies.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Fulfill and manage your orders.</SC.BoldText>
            {` We may use your information to fulfill and manage your orders, payments, 
            returns, and exchanges made through the Sites or Apps.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Request Feedback.</SC.BoldText>
            {` We may use your information to request feedback and 
            to contact you about your use of our Sites or Apps.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>To protect our Sites.</SC.BoldText>
            {` We may use your information as part of our efforts to keep our Sites 
            or Apps safe and secure (for example, for fraud monitoring and prevention).`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>To enable user-to-user communications.</SC.BoldText>
            {` We may use your information in order to enable 
            user-to-user communications with each user's consent.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>To enforce our terms, conditions and policies.</SC.BoldText>
          </SC.Text>
          <SC.Text>
            <SC.BoldText>To respond to legal requests and prevent harm.</SC.BoldText>
            {` If we receive a subpoena or other legal request, we may 
            need to inspect the data we hold to determine how to respond.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>For other Business Purposes.</SC.BoldText>
            {` We may use your information for other Business Purposes, such as data analysis, 
            identifying usage trends, determining the effectiveness of our promotional campaigns 
            and to evaluate and improve our Sites or Apps, products, services, 
            marketing and your experience.`}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>3. Will your information be shared with anyone?</SC.ParagraphTitle>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {` We only share information with your consent, to comply with laws, 
            to protect your rights, or to fulfill business obligations.`}
          </SC.ItalicText>
          <SC.Text>We may process or share data based on the following legal basis:</SC.Text>
          <SC.Text>
            <SC.BoldText>Consent:</SC.BoldText>
            {` We may process your data if you have given us specific 
            consent to use your personal information in a specific purpose.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Legitimate Interests:</SC.BoldText>
            {` We may process your data when it is reasonably 
            necessary to achieve our legitimate business interests.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Performance of a Contract:</SC.BoldText>
            {` Where we have entered into a contract with you, 
            we may process your personal information to fulfill the terms of our contract.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Legal Obligations:</SC.BoldText>
            {` We may disclose your information where we are legally 
            required to do so in order to comply with applicable law, 
            governmental requests, a judicial proceeding, court order, 
            or legal process, such as in response to a court order or a 
            subpoena (including in response to public authorities to meet 
            national security or law enforcement requirements).`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Vital Interests:</SC.BoldText>
            {` We may disclose your information where we believe it is 
            necessary to investigate, prevent, or take action regarding potential 
            violations of our policies, suspected fraud, situations involving potential 
            threats to the safety of any person and illegal activities, or as evidence 
            in litigation in which we are involved.`}
          </SC.Text>
          <SC.Text>
            {`More specifically, we may need to process your data or 
            share your personal information in the following situations:`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Vendors, Consultants and Other Third-Party Service Providers.</SC.BoldText>
            {` We may share your data with third party vendors, service providers, 
            contractors or agents who perform services for us or on our behalf and 
            require access to such information to do that work. Examples include: 
            payment processing, data analysis, email delivery, hosting services, 
            customer service and marketing efforts. We may allow selected third parties 
            to use tracking technology on the Sites or Apps, which will enable them to 
            collect data about how you interact with the Sites or Apps over time. 
            This information may be used to, among other things, analyze and track data, 
            determine the popularity of certain content and better understand online activity. 
            Unless described in this Policy, we do not share, sell, rent or trade any of your 
            information with third parties for their promotional purposes.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Business Transfers.</SC.BoldText>
            {` We may share or transfer your information in connection with, or during 
            negotiations of, any merger, sale of company assets, financing, or acquisition 
            of all or a portion of our business to another company.`}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>4. Who will your information be shared with?</SC.ParagraphTitle>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {' We only share information with the following third parties.'}
          </SC.ItalicText>
          <SC.Text>
            {`We only share and disclose your information with the following third parties. 
            We have categorized each party so that you may be easily understand the purpose 
            of our data collection and processing practices. If we have processed your 
            data based on your consent and you wish to revoke your consent, please contact us.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Allow Users to Connect to their Third-Party Accounts:</SC.BoldText>
            {' Facebook account, Google account, Instagram account and Github account'}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Content Optimization:</SC.BoldText>
            {' YouTube video embed'}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Functionality and Infrastructure Optimization:</SC.BoldText>
            {' Amazon Web Services and Google Cloud Storage'}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Invoice and Billing:</SC.BoldText>
            {' Stripe, PayPal'}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>User Account Registration and Authentication:</SC.BoldText>
            {' Facebook Login, Instagram Authentication, Google Sign-In and GitHub OAuth'}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Web and Mobile Analytics:</SC.BoldText>
            {' Google Analytics, Google Tag Manager, Segment and Intercom'}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Website Performance Monitoring:</SC.BoldText>
            {' Bugsnag and Instabug'}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Website Testing:</SC.BoldText>
            {' TestFlight'}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>5. Do we use cookies and other tracking technologies?</SC.ParagraphTitle>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {' We may use cookies and other tracking technologies to collect and store your information.'}
          </SC.ItalicText>
          <SC.Text>
            {`We may use cookies and similar tracking technologies (like web beacons and pixels) 
            to access or store information. Specific information about how we use such technologies 
            and how you can refuse certain cookies is set out in our Cookie Policy.`}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>6. How do we handle your social logins?</SC.ParagraphTitle>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {` If you choose to register or log in to our websites using a social media account, 
            we may have access to certain information about you.`}
          </SC.ItalicText>
          <SC.Text>
            {`Our Sites or Apps offer you the ability to register and login using 
            your third party social media account details (like your Facebook or Twitter logins). 
            Where you choose to do this, we will receive certain profile information about you 
            from your social media provider. The profile Information we receive may vary 
            depending on the social media provider concerned, but will often include your name, 
            e-mail address, friends list, profile picture as well as other information you choose to make public.`}
          </SC.Text>
          <SC.Text>
            {`We will use the information we receive only for the purposes that are described in 
            this privacy policy or that are otherwise made clear to you on the Sites or Apps. 
            Please note that we do not control, and are not responsible for, other uses of your 
            personal information by your third party social media provider. 
            We recommend that you review their privacy policy to understand how they collect, 
            use and share your personal information, and how you can set your 
            privacy preferences on their sites and apps.`}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>7. What is our stance on third-party websites?</SC.ParagraphTitle>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {` We are not responsible for the safety of any information that you share with 
            third-party providers who advertise, but are not affiliated with, our websites.`}
          </SC.ItalicText>
          <SC.Text>
            {`The Sites or Apps may contain advertisements from third parties that are 
            not affiliated with us and which may link to other websites, 
            online services or mobile applications. We cannot guarantee the safety and 
            privacy of data you provide to any third parties. Any data collected by 
            third parties is not covered by this privacy policy. 
            We are not responsible for the content or privacy and security practices 
            and policies of any third parties, including other websites, services or 
            applications that may be linked to or from the Sites or Apps. You should 
            review the policies of such third parties and contact them 
            directly to respond to your questions.`}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>8. How long do we keep your information?</SC.ParagraphTitle>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {` We keep your information for as long as necessary to fulfill the purposes 
            outlined in this privacy policy unless otherwise required by law.`}
          </SC.ItalicText>
          <SC.Text>
            {`We will only keep your personal information for as long as it is necessary 
            for the purposes set out in this privacy policy, unless a longer retention 
            period is required or permitted by law (such as tax, accounting or other 
            legal requirements). No purpose in this policy will require us keeping your 
            personal information for longer than 2 years past the termination of the user's account.`}
          </SC.Text>
          <SC.Text>
            {`When we have no ongoing legitimate business need to process your personal 
            information, we will either delete or anonymize it, or, if this is not 
            possible (for example, because your personal information has been stored 
            in backup archives), then we will securely store your personal information 
            and isolate it from any further processing until deletion is possible.`}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>9. How do we keep your information safe?</SC.ParagraphTitle>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {` We aim to protect your personal information through 
            a system of organizational and technical security measures.`}
          </SC.ItalicText>
          <SC.Text>
            {`We have implemented appropriate technical and organizational security 
            measures designed to protect the security of any personal information we process. 
            However, please also remember that we cannot guarantee that the internet itself is 100% secure. 
            Although we will do our best to protect your personal information, transmission of personal 
            information to and from our Sites or Apps is at your own risk. You should 
            only access the services within a secure environment.`}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>10. Do we collect information from minors?</SC.ParagraphTitle>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {' We do not knowingly collect data from or market to children under 18 years of age.'}
          </SC.ItalicText>
          <SC.Text>
            {`We do not knowingly solicit data from or market to children under 18 years of age. 
            By using the Sites or Apps, you represent that you are at least 18 or 
            that you are the parent or guardian of such a minor and consent to such minor 
            dependent’s use of the Sites or Apps. If we learn that personal information 
            from users less than 18 years of age has been collected, we will deactivate 
            the account and take reasonable measures to promptly delete such data from our records. 
            If you become aware of any data we have collected from children under age 18, 
            please contact us at datapolicy@dandapani.org.`}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>11. What are your privacy rights?</SC.ParagraphTitle>
          <SC.Text>
            {`If you are resident in the European Economic Area and you believe we 
            are unlawfully processing your personal information, you also have the 
            right to complain to your local data protection supervisory authority. 
            You can find their contact details `}
            <SC.TextLink href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
              here.
            </SC.TextLink>
          </SC.Text>
          <SC.Text>Account Information</SC.Text>
          <SC.Text>
            {`If you would at any time like to review or change the information 
            in your account or terminate your account, you can:`}
          </SC.Text>
          <SC.Text>{'• Log into your account settings and update your user account.'}</SC.Text>
          <SC.Text>
            {`Upon your request to terminate your account, we will deactivate or delete 
            your account and information from our active databases. However, some 
            information may be retained in our files to prevent fraud, troubleshoot problems, 
            assist with any investigations, enforce our Terms of Use and/or 
            comply with legal requirements.`}
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Cookies and similar technologies:</SC.BoldText>
            {` Most Web browsers are set to accept cookies by default. If you prefer, 
            you can usually choose to set your browser to remove cookies and to reject cookies. 
            If you choose to remove cookies or reject cookies, this could affect certain features 
            or services of our Sites or Apps. To opt-out of interest-based advertising by 
            advertisers on our Sites or Apps visit `}
            <SC.TextLink href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</SC.TextLink>
          </SC.Text>
          <SC.Text>
            <SC.BoldText>Opting out of email marketing:</SC.BoldText>
            {` You can unsubscribe from our email list at any time by clicking on the 
            unsubscribe link in the emails that we send.You will then be removed from 
            the email list – however, we will may still need to send you service-related 
            emails that are necessary for the administration and use of your account. 
            To otherwise opt-out, you may:`}
          </SC.Text>
          <SC.Text>{'• Access your account settings and update preferences.'}</SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>12. Controls for do-not-track features</SC.ParagraphTitle>
          <SC.Text>
            {`Most web browsers and some mobile operating systems and mobile applications 
            include a Do-Not-Track (“DNT”) feature or setting you can activate to signal 
            your privacy preference not to have data about your online browsing activities 
            monitored and collected. No uniform technology standard for recognizing and 
            implementing DNT signals has been finalized. As such, we do not currently 
            respond to DNT browser signals or any other mechanism that automatically 
            communicates your choice not to be tracked online. If a standard for online 
            tracking is adopted that we must follow in the future, we will inform you 
            about that practice in a revised version of this Privacy Policy.`}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>13. Do California residents have specific privacy rights?</SC.ParagraphTitle>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {` Yes, if you are a resident of California, you are granted 
            specific rights regarding access to your personal information.`}
          </SC.ItalicText>
          <SC.Text>
            {`California Civil Code Section 1798.83, also known as the “Shine The Light” 
            law, permits our users who are California residents to request and obtain 
            from us, once a year and free of charge, information about categories 
            of personal information (if any) we disclosed to third parties for direct 
            marketing purposes and the names and addresses of all third parties with 
            which we shared personal information in the immediately preceding calendar year. 
            If you are a California resident and would like to make such a request, 
            please submit your request in writing to us using the contact information provided below.`}
          </SC.Text>
          <SC.Text>
            {`If you are under 18 years of age, reside in California, and have a 
            registered account with the Sites or Apps, you have the right to request 
            removal of unwanted data that you publicly post on the Sites or Apps. 
            To request removal of such data, please contact us using the contact 
            information provided below, and include the email address associated 
            with your account and a statement that you reside in California. 
            We will make sure the data is not publicly displayed on the Sites or Apps, 
            but please be aware that the data may not be completely or comprehensively removed from our systems.`}
          </SC.Text>
        </SC.Paragraph>

        <SC.Paragraph>
          <SC.ParagraphTitle>14. Do we make updates to this policy?</SC.ParagraphTitle>
          <SC.ItalicText>
            <SC.BoldText>In short:</SC.BoldText>
            {' Yes, we will update this policy as necessary to stay compliant with relevant laws.'}
          </SC.ItalicText>
          <SC.Text>
            {`We may update this privacy policy from time to time. 
            The updated version will be indicated by an updated “Revised” date and the 
            updated version will be effective as soon as it is accessible. 
            If we make material changes to this privacy policy, we may notify 
            you either by prominently posting a notice of such changes or by 
            directly sending you a notification. We encourage you to review 
            this privacy policy frequently to be informed of how we 
            are protecting your information.`}
          </SC.Text>
        </SC.Paragraph>
      </SC.StyledPage>
    </>
  );
};

export default PrivacyPolicy;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BackNavigation from 'components/BackNavigation';
import CourseCard from 'components/CourseCard';
import HeaderWithSteps from 'components/HeaderWithSteps';
import * as purchaseActions from 'resources/purchase/purchase.actions';
import * as purchaseSelectors from 'resources/purchase/purchase.selectors';

import ShotsImg from './assets/asset.jpg';
import SignUpForm from './components/SignUpForm';
import quote from './quote';
import * as SC from './SignUp.styled';

const ufId = 9;

const SignUp = () => {
  const dispatch = useDispatch();
  const productId = useSelector((state) => purchaseSelectors.getInitialProductId(state));
  const product = useSelector((state) => purchaseSelectors.getInitialProductInfo(state));

  useEffect(() => {
    if (productId) {
      dispatch(purchaseActions.getInitialProductInfo(productId)).catch(() => {});
    }
  }, [productId, dispatch]);

  const ufPage = productId === ufId;

  return (
    <>
      <HeaderWithSteps currentStep={1} stepName="Sign Up" />

      <SC.StyledPage>
        <SC.SideView backgroundColor={ufPage ? '#1b1c20' : undefined}>
          {!ufPage ? (
            <>
              <SC.PatternTop />
              <SC.Bottom>
                <SC.Quote>
                  <SC.QuoteText>{quote.text}</SC.QuoteText>
                  <SC.QuoteAuthor>{quote.author}</SC.QuoteAuthor>
                </SC.Quote>
              </SC.Bottom>
              <SC.PatternBottom />
            </>
          ) : (
            <>
              <SC.Hero>
                <SC.HeroTitle>Get the Unwavering Focus video course and app to build focus and willpower.</SC.HeroTitle>
                <SC.HeroImage src={ShotsImg} alt="Image shows the app's screenshots" loading="lazy" decoding="async" />
              </SC.Hero>
              <SC.UFDescription>
                <SC.UfDescription>
                  10 chapters consisting of over 50 lessons delivered through high-quality video and audio beautifully
                  designed for iOS and Android along with quizzes, reflections, and exercises for each chapter.
                </SC.UfDescription>
                <SC.UFChapterList>
                  <li>Chapter 1: Foundations for a concentrated mind</li>
                  <li>Chapter 2: Understanding the mind.</li>
                  <li>Chapter 3: Understanding awareness</li>
                  <li>Chapter 4: Mastering awareness.</li>
                  <li>Chapter 5: Introduction to concentration.</li>
                  <li>Chapter 6: The science and art of concentration.</li>
                  <li>Chapter 7: Cogitations on concentration.</li>
                  <li>Chapter 8: Willpower - Life's Greatest Force</li>
                  <li>Chapter 9: Willpower extended.</li>
                  <li>Chapter 10: Concentration a Panacea for the Mind.</li>
                </SC.UFChapterList>
                <SC.UFQuote>
                  <SC.Quote2>
                    <SC.QuoteText2>{quote.text}</SC.QuoteText2>
                    <SC.QuoteAuthor2>{quote.author}</SC.QuoteAuthor2>
                  </SC.Quote2>
                </SC.UFQuote>
              </SC.UFDescription>
            </>
          )}
        </SC.SideView>

        {!ufPage && product.name && <CourseCard courseName={product.name} price={product.regularPrice} absolute />}

        <SC.SignUpPart>
          {ufPage && product.name && (
            <>
              <SC.MobileHero>
                <SC.HeroTitle>Get the Unwavering Focus video course and app to build focus and willpower.</SC.HeroTitle>
                <SC.HeroImage src={ShotsImg} alt="Image shows the app's screenshots" />
              </SC.MobileHero>
              <SC.MobileUFDescription>
                <SC.UfDescription>
                  10 chapters consisting of over 50 lessons delivered through high-quality video and audio beautifully
                  designed for iOS and Android along with quizzes, reflections, and exercises for each chapter.
                </SC.UfDescription>
                <SC.UFChapterList>
                  <li>Chapter 1: Foundations for a concentrated mind</li>
                  <li>Chapter 2: Understanding the mind.</li>
                  <li>Chapter 3: Understanding awareness</li>
                  <li>Chapter 4: Mastering awareness.</li>
                  <li>Chapter 5: Introduction to concentration.</li>
                  <li>Chapter 6: The science and art of concentration.</li>
                  <li>Chapter 7: Cogitations on concentration.</li>
                  <li>Chapter 8: Willpower - Life's Greatest Force</li>
                  <li>Chapter 9: Willpower extended.</li>
                  <li>Chapter 10: Concentration a Panacea for the Mind.</li>
                </SC.UFChapterList>
                <SC.UFQuote>
                  <SC.Quote2>
                    <SC.QuoteText2>{quote.text}</SC.QuoteText2>
                    <SC.QuoteAuthor2>{quote.author}</SC.QuoteAuthor2>
                  </SC.Quote2>
                </SC.UFQuote>
              </SC.MobileUFDescription>
            </>
          )}

          <SC.SignUp>
            {ufPage && product.name && (
              <SC.UFShadow>
                <CourseCard courseName={product.name} price={product.regularPrice} />
              </SC.UFShadow>
            )}

            <BackNavigation to="/sign-in" title="Go back to Sign In" />
            <SC.SignUpMain>
              <SC.SignUpTitle>Sign Up</SC.SignUpTitle>
              <SignUpForm />
            </SC.SignUpMain>
            <SC.SignUpFotter>
              <SC.PrivacyPolicyLink to="/privacy-policy">Privacy Policy</SC.PrivacyPolicyLink>
            </SC.SignUpFotter>
          </SC.SignUp>
        </SC.SignUpPart>
      </SC.StyledPage>
    </>
  );
};

export default SignUp;

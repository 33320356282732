import styled from 'styled-components';

import colors from 'styles/colors';
import metrics from 'styles/metrics';

export const Close = styled.button`
  align-self: flex-end;
  cursor: pointer;
`;

export const Error = styled.div`
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  padding: 0px 30px 15px 30px;
  border-bottom: 2px solid ${colors.error};
`;

export const Text = styled.div`
  margin: 20px 30px 30px 30px;
  letter-spacing: 0.05em;
  text-align: center;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin: 5px 13px 20px 13px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 171px;
`;

import React from 'react';
import PropTypes from 'prop-types';

import { PRODUCTS } from 'utils/enums';

import * as SC from '../../CourseDescription.styled';

const PackageContent = ({ product }) => {
  return (
    <SC.Content>
      <SC.Title>What's in Package</SC.Title>
      <SC.SubTitle>you’ll receive :</SC.SubTitle>
      <SC.List>
        {product.meta.packageContent.map((item, index) => (
          <SC.Item key={index}>
            <SC.Indicator />
            <SC.ItemText>{item.children}</SC.ItemText>
          </SC.Item>
        ))}
      </SC.List>
    </SC.Content>
  );
};

PackageContent.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default PackageContent;

import styled from 'styled-components';

import { ReactComponent as AccountIcon } from 'assets/create-account.svg';
import { Link } from 'styles/commonStyledComponents';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const SignInForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const CreateAccount = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 15px;
`;

export const CreateAccountLink = styled.div`
  font-size: ${fonts.size.regular};

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: ${fonts.size.small};
  }
`;

export const CreateAccountIcon = styled(AccountIcon)`
  margin-right: 16px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

export const getInitialProductId = ({ purchase }) => purchase.initialProduct.productId;

export const getInitialProductInfo = ({ purchase }) => ({
  productId: purchase.initialProduct.productId,
  name: purchase.initialProduct.name,
  regularPrice: purchase.initialProduct.regularPrice,
});

export const getProduct = ({ purchase }) => purchase.product;

export const getNewProduct = ({ purchase }) => purchase.newProduct;

export const getPromoCodeApplied = ({ purchase }) => purchase.promoApplied;

export const getRegularPrice = ({ purchase }) => purchase.regularPrice;

import styled from 'styled-components';

import { ReactComponent as CardImage } from 'assets/card.svg';
import { ReactComponent as PaypalImage } from 'assets/paypal.svg';
import colors from 'styles/colors';

export const PayWithPaypalForm = styled.div``;

export const PaypalPayment = styled.div``;

export const PaymentLabel = styled.div`
  margin-bottom: 13px;
`;

export const Paypal = styled.div`
  display: flex;
  align-items: center;
  height: 47px;
  border: 1px solid ${colors.border};
  padding-left: 14px;
`;

export const PaypalIcon = styled(PaypalImage)`
  height: 20px;
  width: auto;
`;

export const PaypalCard = styled.div`
  background-color: #faf9f9;
  border: 1px solid ${colors.border};
  border-top: none;
  padding: 34px 62px 34px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardIcon = styled(CardImage)``;

export const PaypalCardText = styled.div`
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: rgba(0, 0, 0, 0.62);
  margin-top: 25px;
`;

import React from 'react';
import PropTypes from 'prop-types';

import * as SC from './HeaderWithSteps.styled';

const HeaderWithSteps = ({ currentStep, stepName = 'Sign In' }) => (
  <SC.HeaderWithSteps>
    <SC.Step selected={currentStep === 1}>
      <SC.StepNumber>1.</SC.StepNumber>
      <SC.StepName>{stepName}</SC.StepName>
    </SC.Step>
    <SC.SeparatingLine1 />
    <SC.Step selected={currentStep === 2}>
      <SC.StepNumber>2.</SC.StepNumber>
      <SC.StepName>Payment</SC.StepName>
    </SC.Step>
    <SC.SeparatingLine2 />
    <SC.Step selected={currentStep === 3}>
      <SC.StepNumber>3.</SC.StepNumber>
      <SC.StepName>Confirmation</SC.StepName>
    </SC.Step>
  </SC.HeaderWithSteps>
);

HeaderWithSteps.propTypes = {
  currentStep: PropTypes.number,
};

HeaderWithSteps.defaultProps = {
  currentStep: null,
};

export default HeaderWithSteps;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Modal from 'components/Modal';

import * as SC from './ProfileModal.styled';

const ProfileModal = ({ visible, onClose }) => {
  const onCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal visible={visible}>
      <SC.Close onClick={onCloseModal} />
      <SC.Text>Your data has been changed successfully.</SC.Text>
      <Button title="Close" onClick={onCloseModal} />
    </Modal>
  );
};

ProfileModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

ProfileModal.defaultProps = {
  visible: false,
};

export default ProfileModal;

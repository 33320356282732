import styled from 'styled-components';

import colors from 'styles/colors';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const Modal = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-x: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(26, 25, 75, 0.33);

  box-sizing: border-box;

  font-family: ${fonts.type.base};
  font-size: ${fonts.size.regular};
  font-weight: ${fonts.weight.regular};
  line-height: ${fonts.lineHeight.regular};
  color: ${colors.fontPrimary};
`;

export const Content = styled.div`
  padding: 20px 25px 45px 25px;
  background-color: ${colors.backgroundPage};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  max-width: 676px;

  @media only screen and (max-width: ${metrics.mobile}) {
    width: 311px;
  }
`;

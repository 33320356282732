import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import CourseDescription from 'components/CourseDescription';
import HeaderWithSteps from 'components/HeaderWithSteps';
import * as purchaseActions from 'resources/purchase/purchase.actions';
import * as purchaseSelectors from 'resources/purchase/purchase.selectors';
import fonts from 'styles/fonts';

import InfoPopup from './components/InfoPopup';
import PaymentSection from './components/PaymentSection';
import * as SC from './Payment.styled';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

const Payment = () => {
  const match = useRouteMatch('/payment/:productId');
  const lastLocation = useLastLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const product = useSelector((state) => purchaseSelectors.getProduct(state));

  useEffect(() => {
    (async () => {
      const productId = match ? match.params.productId : null;
      try {
        await dispatch(purchaseActions.getProduct(productId));
        setIsLoaded(true);
      } catch (error) {
        history.push('/');
      }
    })();
  }, []);

  const onNavigateBack = useCallback(() => {
    history.push('/');
  }, [history]);

  const headerType = useMemo(() => {
    if (!lastLocation) {
      return 'navigation';
    }
    if (lastLocation.pathname === '/sign-in' || lastLocation.pathname === '/sign-up') {
      return 'steps';
    }
    return 'navigation';
  }, [lastLocation]);

  if (!isLoaded) {
    return null;
  }

  return (
    <SC.PaymentPage>
      {headerType === 'steps' && <HeaderWithSteps currentStep={2} />}

      {headerType === 'navigation' && (
        <SC.BackNavigation>
          <SC.BackNavigationImage />
          <SC.BackNavigationText onClick={onNavigateBack}>Go back to product selection</SC.BackNavigationText>
        </SC.BackNavigation>
      )}

      <SC.StyledPage>
        <Elements
          stripe={stripePromise}
          options={{
            fonts: [{ cssSrc: fonts.fontsSrc }],
            locale: 'en',
          }}
        >
          <PaymentSection />
        </Elements>

        <CourseDescription product={product} />
        <InfoPopup />
      </SC.StyledPage>
    </SC.PaymentPage>
  );
};

export default Payment;

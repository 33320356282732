import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from 'assets/close.svg';

import Button from '../Button';
import Modal from '../Modal';
import * as SC from './SuccessModal.styled';

const SuccessModal = ({ visible, onClose, withoutButton, buttonTitle, onButtonClick, children }) => {
  const onCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const onClick = useCallback(() => {
    if (onButtonClick) {
      onButtonClick();
    } else {
      onClose();
    }
  }, [onButtonClick, onClose]);

  return (
    <Modal visible={visible}>
      <SC.CloseButton onClick={onCloseModal} data-testid="success-modal-close">
        <CloseIcon />
      </SC.CloseButton>
      {children}
      {!withoutButton && (
        <SC.ButtonWrapper>
          <Button title={buttonTitle} onClick={onClick} />
        </SC.ButtonWrapper>
      )}
    </Modal>
  );
};

SuccessModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string,
  withoutButton: PropTypes.bool,
  onButtonClick: PropTypes.func,
  children: PropTypes.element.isRequired,
};

SuccessModal.defaultProps = {
  visible: false,
  buttonTitle: 'Close',
  withoutButton: false,
  onButtonClick: null,
};

export default SuccessModal;

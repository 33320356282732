import styled from 'styled-components';

import colors from 'styles/colors';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const HeaderWithSteps = styled.div`
  padding: 0px 140px;
  height: ${metrics.headerHeight};
  background-color: ${colors.backgroundStepsHeader};
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${metrics.mobile}) {
    padding: 0px 30px;
    height: ${metrics.headerHeight};
  }
`;

export const StepNumber = styled.div`
  font-size: 20px;
  margin-right: 10px;

  @media only screen and (max-width: ${metrics.mobile}) {
    display: none;
  }
`;

export const StepName = styled.div`
  font-size: ${fonts.size.regular};
`;

export const Step = styled.div`
  display: flex;
  align-items: baseline;
  color: ${colors.fontSecondary};
  line-height: 24px;

  ${StepNumber} {
    font-weight: ${(props) => props.selected && fonts.weight.medium};
    opacity: ${(props) => !props.selected && '0.7'};
  }

  ${StepName} {
    font-weight: ${(props) => props.selected && fonts.weight.semiBold};
    opacity: ${(props) => !props.selected && '0.7'};
  }
`;

export const SeparatingLine1 = styled.div`
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  flex-grow: 1;
  margin: 0px 30px;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin: 0px 10px;
  }
`;

export const SeparatingLine2 = styled.div`
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  flex-grow: 1;
  margin: 0px 30px;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin: 0px 10px;
  }
`;

export const getAuthenticated = ({ user }) => user.authenticated;

export const getMainUserInfo = ({ user }) => ({
  id: user.id,
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email,
  country: user.country,
  authenticated: user.authenticated,
  hasActiveSubscription: user.has_active_subscription,
});

export const getLegacyUserEmail = ({ user }) => user.legacyEmail;

export const getEmailVerified = ({ user }) => user.activated;

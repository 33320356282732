import {
  CHANGED_EMAIL,
  GET_USER,
  LEGACY_USER,
  SET_AUTH,
  SIGN_IN_USER,
  SIGN_OUT_USER,
  SIGN_UP_USER,
  UPDATED_USER,
} from './user.actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        ...action.user,
      };
    case SIGN_UP_USER:
    case SIGN_IN_USER:
    case SET_AUTH:
      return {
        ...state,
        ...action.user,
        authenticated: true,
      };
    case LEGACY_USER:
      return {
        ...state,
        legacyEmail: action.legacyUser.legacy_email,
      };
    case SIGN_OUT_USER:
      return {};
    case UPDATED_USER:
      return {
        ...state,
        ...action.user,
      };
    case CHANGED_EMAIL:
      return {
        ...state,
        ...action.user,
      };
    default:
      return state;
  }
};

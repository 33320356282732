import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Input from 'components/Input';
import useModal from 'hooks/useModal';
import { FormError, useForm } from 'hooks/useReactHookForm';
import { useAppDispatch, useAppSelector } from 'resources/hooks';
import * as userActions from 'resources/user/user.actions';
import * as userSelectors from 'resources/user/user.selectors';

import ProfileModal from '../ProfileModal';
import * as SC from './EmailTab.styled';

interface FormValues extends FormError {
  email: string;
  confirm_email: string;
  password: string;
}

const EmailTab = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => userSelectors.getMainUserInfo(state));

  const [visibleSuccessModal, setHiddenSuccessModal, setVisibleSuccessModal] = useModal(false);

  const {
    handleSubmit,
    globalError,
    mapFormErrors,
    register,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: user.email,
    },
  });

  const onSubmit = handleSubmit(async (values) => {
    try {
      await dispatch(userActions.changeEmail(values));
      setVisibleSuccessModal();
      dispatch(userActions.signOut());
      history.push('/sign-in');
    } catch (error) {
      mapFormErrors(error);
    }
  });

  return (
    <SC.EmailTab onSubmit={onSubmit}>
      <Input
        label="New Email Address"
        placeholder="New Email Address"
        required
        type="email"
        autoComplete="email"
        error={errors.email}
        {...register('email', {
          required: true,
          maxLength: 127,
        })}
      />
      <Input
        label="Confirm Email Address"
        placeholder="Retype New Email Address"
        required
        type="email"
        autoComplete="email"
        error={errors.email}
        {...register('confirm_email', {
          required: true,
          maxLength: 127,
        })}
      />
      <Input
        label="Password"
        type="password"
        required
        placeholder="Enter Password"
        autoComplete="current-password"
        error={errors.email}
        {...register('password', {
          required: true,
          maxLength: 127,
        })}
      />
      {globalError && <ErrorText error={globalError} negativeBottomMargin />}
      <SC.ButtonWrapper>
        <Button title="Change Email" type="submit" disabled={isSubmitting} />
      </SC.ButtonWrapper>
      <ProfileModal visible={visibleSuccessModal} onClose={setHiddenSuccessModal} />
    </SC.EmailTab>
  );
};

export default EmailTab;

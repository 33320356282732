import React from 'react';
import { useDispatch } from 'react-redux';

import Loader from 'components/Loader';
import { generateStripePortal } from 'resources/purchase/purchase.actions';

const RitualsSubscription = ({ match, history }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const id = match.params.sub === 'annual' ? 1 : 2;
    dispatch(generateStripePortal(id))
      .then((url) => {
        window.location.href = url;
      })
      .catch((e) => {
        history.replace('/');
      });
  }, [match.params.sub, history.replace]);

  return (
    <div>
      <Loader visible />
    </div>
  );
};

export default React.memo(RitualsSubscription);

const metrics = {
  mobile: '768px',
  tablet: '992px',
  largeTablet: '1200px',

  headerHeight: '66px',

  minNonMobile: '769px',
};

export default metrics;

import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Input from 'components/Input';
import SuccessModal from 'components/SuccessModal';
import useModal from 'hooks/useModal';
import { FormError, useForm } from 'hooks/useReactHookForm';
import * as userActions from 'resources/user/user.actions';

import * as SC from './ResetPassword.styled';

interface FormValues extends FormError {
  new_password1: string;
  new_password2: string;
}

const ResetPassword = () => {
  const history = useHistory();
  const { uidb64, token } = useParams<{ uidb64: string; token: string }>();

  const [visibleSuccessModal, setHiddenSuccessModal, setVisibleSuccessModal] = useModal(false);

  const {
    mapFormErrors,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    register,
    globalError,
  } = useForm<FormValues>();

  const onSubmit = handleSubmit(async (values) => {
    try {
      await userActions.resetPassword(values, uidb64, token);
      setVisibleSuccessModal();
    } catch (error) {
      if ((error as any)?.status === 404) {
        history.replace('/forgot-password?error=1');
      } else {
        mapFormErrors(error);
      }
    }
  });

  const onNavigateToSignIn = useCallback(() => {
    history.push('/sign-in');
  }, [history]);

  const validateRetypePassword = useCallback(
    (value: string) => {
      if (value !== getValues().new_password1) {
        return "Passwords don't match.";
      }
    },
    [getValues]
  );

  return (
    <SC.Container>
      <SC.StyledPage>
        <SC.Form onSubmit={onSubmit}>
          <SC.Title>Create new password</SC.Title>
          <Input
            label="New Password"
            type="password"
            autoComplete="new-password"
            placeholder="Enter New Password"
            error={errors.new_password1}
            {...register('new_password1', {
              required: true,
              maxLength: 127,
            })}
          />
          <Input
            label="Confirm New Password"
            type="password"
            autoComplete="new-password"
            placeholder="Retype New Password"
            error={errors.new_password2}
            {...register('new_password2', {
              required: true,
              maxLength: 127,
              validate: validateRetypePassword,
            })}
          />
          {globalError && <ErrorText error={globalError} negativeBottomMargin />}

          <SC.ButtonWrapper>
            <Button title="Change password" type="submit" disabled={isSubmitting} />
          </SC.ButtonWrapper>
        </SC.Form>
        <SuccessModal
          visible={visibleSuccessModal}
          onClose={setHiddenSuccessModal}
          buttonTitle="Sign In"
          onButtonClick={onNavigateToSignIn}
        >
          <SC.Text>Your password has been changed successfully.</SC.Text>
        </SuccessModal>
      </SC.StyledPage>
    </SC.Container>
  );
};

export default ResetPassword;

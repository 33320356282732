import { useMemo } from 'react';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import Input from 'components/Input';
import { FormError, getFieldError, useForm } from 'hooks/useReactHookForm';
import { useAppDispatch, useAppSelector } from 'resources/hooks';
import * as purchaseActions from 'resources/purchase/purchase.actions';
import * as purchaseSelectors from 'resources/purchase/purchase.selectors';
import metrics from 'styles/metrics';
import { priceToString } from 'utils/price';
import regex from 'utils/regex';

import { useMedia } from '../../../../hooks/useMedia';
import * as SC from './ProductInfo.styled';

interface FormValues extends FormError {
  discountCode: string;
}

const ProductInfo = () => {
  const dispatch = useAppDispatch();

  const product = useAppSelector((state) => purchaseSelectors.getProduct(state));
  const newProduct = useAppSelector((state) => purchaseSelectors.getNewProduct(state));
  const promoCodeApplied = useAppSelector((state) => purchaseSelectors.getPromoCodeApplied(state));
  const regularPrice = useAppSelector((state) => purchaseSelectors.getRegularPrice(state));

  const isTablet = useMedia([`(max-width: ${metrics.largeTablet})`], [true], false);

  const {
    register,
    handleSubmit,
    mapFormErrors,
    formState: { errors, isSubmitting },
    globalError,
  } = useForm<FormValues>({
    defaultValues: {
      discountCode: promoCodeApplied,
    },
  });

  const onSubmit = handleSubmit(async ({ discountCode }) => {
    try {
      if (promoCodeApplied) {
        await dispatch(purchaseActions.removePromoCode(product.id));
      } else {
        await dispatch(
          purchaseActions.apllyPromoCode(product.id, {
            discount_code: discountCode,
          })
        );
      }
    } catch (error) {
      mapFormErrors(error);
    }
  });

  const titleWithSubtitle = product.name && product.name.match(regex.subtitle);

  const saleDiscount = useMemo(() => regularPrice - product.full_price, [regularPrice, product]);
  const promo = useMemo(() => (newProduct.id === product.id ? product.full_price - newProduct.current_price : 0), [
    newProduct,
    product,
  ]);
  const totalPrice = useMemo(() => regularPrice - saleDiscount - promo, [regularPrice, saleDiscount, promo]);

  return (
    <SC.ProductInfo>
      {isTablet && <SC.MobileSectionTitle>Order Summary</SC.MobileSectionTitle>}
      <SC.CourseInfo>
        <SC.CourseImage />
        <SC.CourseInfoContainer>
          <SC.CourseName>
            <SC.CourseTitle>{titleWithSubtitle ? titleWithSubtitle[1] : product.name}</SC.CourseTitle>
            {titleWithSubtitle && <SC.CourseSubtitle>{titleWithSubtitle[2]}</SC.CourseSubtitle>}
          </SC.CourseName>
          <SC.CoursePrice>${priceToString(regularPrice)}</SC.CoursePrice>
        </SC.CourseInfoContainer>
      </SC.CourseInfo>

      <SC.PromoCode onSubmit={onSubmit}>
        <SC.PromoCodeLabel>Promo Code</SC.PromoCodeLabel>
        <SC.PromoCodeContainer>
          <Input
            placeholder={isTablet ? 'Enter Promo Code' : 'Enter Code and click Apply'}
            disabled={!!promoCodeApplied}
            {...register('discountCode', {
              required: true,
              maxLength: 50,
            })}
          />
          <SC.ApllyPromoCodeButton>
            <Button title={promoCodeApplied ? 'Remove' : 'Apply'} type="submit" disabled={isSubmitting} />
          </SC.ApllyPromoCodeButton>
        </SC.PromoCodeContainer>
        {(globalError || getFieldError(errors.discountCode)) && (
          <ErrorText error={getFieldError(errors.discountCode) || globalError} />
        )}
        <SC.PromoCodeDescription>In order for the Promo Code to work, please click "APPLY".</SC.PromoCodeDescription>
      </SC.PromoCode>

      {!!(saleDiscount || promoCodeApplied) && (
        <SC.Discounts>
          <SC.Discount>
            <SC.DiscountKey>Subtotal</SC.DiscountKey>
            <SC.DiscountPrice>${priceToString(regularPrice)}</SC.DiscountPrice>
          </SC.Discount>
          {!!saleDiscount && (
            <SC.Discount>
              <SC.DiscountKey>Bundle Discount</SC.DiscountKey>
              <SC.DiscountPrice>-${priceToString(saleDiscount)}</SC.DiscountPrice>
            </SC.Discount>
          )}
          {!!promoCodeApplied && (
            <SC.Discount data-testid="promo-price">
              <SC.PromoCodeWithTag>
                <SC.DiscountKey>Promo Code</SC.DiscountKey>
                <SC.PromoTag>
                  <SC.PromoTagIcon />
                  <SC.PromoTagTitle>{promoCodeApplied}</SC.PromoTagTitle>
                </SC.PromoTag>
              </SC.PromoCodeWithTag>
              <SC.DiscountPrice>-${priceToString(promo)}</SC.DiscountPrice>
            </SC.Discount>
          )}
        </SC.Discounts>
      )}

      <SC.Total>
        <SC.TotalText>Total:</SC.TotalText>
        <SC.TotalPrice data-testid="total-price">
          {!!(regularPrice - totalPrice) && <SC.PreviousPrice>${priceToString(regularPrice)}</SC.PreviousPrice>}
          <SC.ResultPrice>${priceToString(totalPrice)}</SC.ResultPrice>
        </SC.TotalPrice>
      </SC.Total>
      <SC.OrderButton>
        <Button title="Order Now" type="submit" form="payment-form" />
      </SC.OrderButton>
    </SC.ProductInfo>
  );
};

export default ProductInfo;

import styled from 'styled-components';

export const StyledRadioButton = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 17px;
`;

export const RadioButton = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: 1px solid #000000;
  background-color: ${(props) => (props.selected ? '#000000' : '#FFFFFF')};
  position: relative;

  :after {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #ffffff;
    position: absolute;
    top: 3px;
    left: 3px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import regex from 'utils/regex';

import * as SC from '../../CourseDescription.styled';

const CourseInfo = ({ product }) => {
  const titleWithSubtitle = product.name ? product.name.match(regex.subtitle) : [];

  return (
    <SC.Content>
      <SC.Title>{titleWithSubtitle ? titleWithSubtitle[1] : product.name}</SC.Title>
      {titleWithSubtitle && <SC.SubTitle>{titleWithSubtitle[2]}</SC.SubTitle>}
      <SC.Text>{product.promo_description}</SC.Text>
    </SC.Content>
  );
};

CourseInfo.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    promo_description: PropTypes.string,
  }),
};

export default CourseInfo;

import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from 'assets/down-arrow.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';
import colors from 'styles/colors';
import { Page } from 'styles/commonStyledComponents';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const StyledPage = styled(Page)`
  padding-top: 57px;
  padding-bottom: 62px;

  @media only screen and (max-width: ${metrics.mobile}) {
    padding-bottom: 54px;
  }
`;

export const Greeting = styled.div``;

export const GreetingTitle = styled.div`
  font-size: ${fonts.size.header};
  line-height: 55px;
  margin-bottom: 12px;
`;

export const GreetingSubtitle = styled.div``;

export const ProductList = styled.div`
  padding: 50px 0px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -40px;

  @media only screen and (max-width: ${metrics.mobile}) {
    padding: 40px 0px;
  }
`;

export const CourseImageContainer = styled.div`
  background-color: ${colors.darkTheme};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 160px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const CourseImage = styled(Logo)`
  width: 88px;
  height: auto;
`;

export const CourseCard = styled.div`
  box-shadow: 4px 4px 10px rgba(200, 206, 228, 0.25);
  border-radius: 4px;
  margin-right: 40px;

  margin-top: ${(props) => (props.selected ? '0px' : '15px')};
  margin-bottom: ${(props) => (props.selected ? '0px' : '15px')};

  ${CourseImageContainer} {
    height: ${(props) => props.selected && '190px'};
  }

  ${CourseImage} {
    width: ${(props) => props.selected && '110px'};
  }

  :hover {
    box-shadow: 4px 4px 10px rgba(200, 206, 228, 0.5);
    cursor: pointer;
  }

  :last-child {
    margin-right: 0px;
  }
`;

export const CourseInfoContainer = styled.div`
  padding: 30px 23px 30px 20px;
  display: flex;
  justify-content: space-between;
`;

export const CourseInfo = styled.div``;

export const CourseName = styled.div`
  font-size: ${fonts.size.subtitle};
  margin-bottom: 7px;
`;

export const CourseAccess = styled.div`
  text-transform: uppercase;
  font-weight: ${fonts.weight.light};
  font-size: ${fonts.size.small};
  letter-spacing: 0.1em;
`;

export const Status = styled.div`
  color: #34cc82;
  font-size: ${fonts.size.small};
  text-transform: uppercase;
  font-weight: ${fonts.weight.medium};
`;

export const PriceContainer = styled.div`
  position: relative;
`;

export const PreviousPrice = styled.div`
  position: absolute;
  top: -20px;
  right: 0;

  letter-spacing: 0.15em;
  color: rgba(0, 0, 0, 0.3);
  font-size: ${fonts.size.tiny};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #b3b3b3;
    transform: rotate(-7deg);
  }
`;

export const CurrentPrice = styled.div`
  font-size: ${fonts.size.subtitle};
  font-weight: ${fonts.weight.medium};
  margin-bottom: 7px;
`;

export const ProductInfoBelow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-top: 40px;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin-top: 0px;
  }
`;

export const ProductInfoBelowText = styled.div`
  text-transform: uppercase;
  font-size: ${fonts.size.small};
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 10px;
`;

export const ProductInfoBelowIcon = styled(ArrowIcon)``;

import styled from 'styled-components';

import { ReactComponent as BackArrowIcon } from 'assets/back-arrow.svg';
import colors from 'styles/colors';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const PageHeader = styled.div`
  padding: 8px 140px 40px 140px;
  background-color: ${colors.backgroundTheme};
  color: ${colors.fontSecondary};

  @media only screen and (max-width: ${metrics.mobile}) {
    padding: 15px 30px 40px 30px;
  }
`;

export const BackNavigation = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
`;

export const BackNavigationImage = styled(BackArrowIcon)`
  margin-right: 7px;
  margin-bottom: 2px;
`;

export const BackNavigationText = styled.div`
  font-size: ${fonts.size.tiny};
  text-transform: uppercase;
  line-height: 24px;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: ${fonts.size.extraSmall};
  }
`;

export const PageTitle = styled.div`
  font-size: ${fonts.size.pageHeader};
  letter-spacing: -0.02em;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: ${fonts.size.header};
  }
`;

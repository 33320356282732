import React from 'react';

import * as SC from './Button.styled';

interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'as' | 'children' | 'title' | 'ref'> {
  small?: boolean;
  title?: React.ReactNode;
  disabled?: boolean;
}

const Button = ({ title, onClick, disabled = false, small = false, ...props }: ButtonProps) => (
  <SC.StyledButton onClick={onClick} disabled={disabled} small={small} {...props}>
    {title}
  </SC.StyledButton>
);

export default Button;

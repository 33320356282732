import React from 'react';
import PropTypes from 'prop-types';

import * as SC from './TabView.styled';

const TabView = ({ tabs, selectedTab, onTabSelect }) => {
  const renderSelectedTabContent = tabs.find((tab) => tab.id === selectedTab).renderContent;

  return (
    <SC.TabView>
      <SC.Tabs>
        {tabs.map((tab) => (
          <SC.Tab key={tab.id} selected={tab.id === selectedTab} onClick={() => onTabSelect(tab.id)}>
            {tab.title}
          </SC.Tab>
        ))}
      </SC.Tabs>
      <SC.TabContent>{renderSelectedTabContent}</SC.TabContent>
    </SC.TabView>
  );
};

TabView.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      renderContent: PropTypes.node,
    })
  ),
  selectedTab: PropTypes.number.isRequired,
  onTabSelect: PropTypes.func.isRequired,
};

export default TabView;

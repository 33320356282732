import styled from 'styled-components';

import { ReactComponent as CourseLogo } from 'assets/course-logo.svg';
import colors from 'styles/colors';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const CourseCard = styled.div`
  background-color: ${colors.backgroundPage};
  padding: 22px 36px;
  ${(props) =>
    props.absolute &&
    `
    position: absolute;
    left: 0;
    top: 45px;
    width: 566px;

    @media only screen and (max-width: ${metrics.largeTablet}) {
      width: 45%;
    }
  `}

  @media only screen and (max-width: ${metrics.mobile}) {
    width: 100%;
    position: initial;
    border-bottom: 1px solid rgba(22, 22, 22, 0.37);
    background-color: #fcfcfc;
  }
`;

export const Title = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(91, 72, 72, 0.54);
  font-weight: ${fonts.weight.medium};
`;

export const Course = styled.div`
  display: flex;
  margin-top: 16px;

  @media only screen and (max-width: ${metrics.mobile}) {
    flex-direction: column;
  }
`;

export const CourseImage = styled(CourseLogo)`
  width: 41px;

  @media only screen and (max-width: ${metrics.mobile}) {
    width: 29px;
  }
`;

export const CourseTitle = styled.div`
  margin-left: 22px;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin-left: 0px;
  }
`;

export const CourseMainTitle = styled.div`
  font-weight: ${fonts.weight.medium};
  max-width: 236px;
  letter-spacing: 0.1em;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: ${fonts.size.medium};
  }
`;

export const CourseSubtitle = styled.div`
  letter-spacing: 0.1em;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: ${fonts.size.medium};
  }
`;

export const CoursePrice = styled.div`
  font-size: ${fonts.size.small};
  font-weight: ${fonts.weight.semiBold};
  flex-grow: 1;
  text-align: end;
  letter-spacing: 0.15em;
`;

export const CourseInfo = styled.div`
  display: flex;
  flex-grow: 1;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin-top: 8px;
  }
`;

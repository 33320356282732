import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';

import * as SC from './NoPageFound.styled';

const NoPageFound = () => {
  const history = useHistory();

  const onReturnToHome = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <SC.NotFoundPage>
      <SC.Error>
        <SC.ErrorCode>404</SC.ErrorCode>
        <SC.Title>Sorry, the page was not found.</SC.Title>
        <SC.Subtitle>The link you followed is probably broken or the page has been removed.</SC.Subtitle>
        <SC.ButtonWrapper>
          <Button title="Return to homepage" onClick={onReturnToHome} />
        </SC.ButtonWrapper>
      </SC.Error>
    </SC.NotFoundPage>
  );
};

export default NoPageFound;

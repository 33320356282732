import styled from 'styled-components';

import { ReactComponent as PatternImage } from 'assets/half-round-pattern.svg';
import { ReactComponent as BackArrowIcon } from 'assets/prev-arrow.svg';
import colors from 'styles/colors';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const CourseDescription = styled.div`
  display: flex;

  @media only screen and (max-width: ${metrics.tablet}) {
    display: block;
  }

  @media only screen and (min-width: ${metrics.tablet}) {
    min-height: 810px;
  }

  @media only screen and (min-width: ${metrics.largeTablet}) {
    min-height: 100vh;
  }
`;

export const DescriptionSection = styled.div`
  width: 50%;
  background-color: #0b0a26;
  padding: 0px 110px 0px 140px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    padding: 0px 32px 0px 32px;
  }

  @media only screen and (max-width: ${metrics.tablet}) {
    width: 100%;
    justify-content: center;
    padding: 80px 32px 94px 32px;
  }
`;

export const PointsSection = styled.div`
  width: 50%;
  background-color: #1a194b;
  padding: 43px 0px 50px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    justify-content: flex-end;
  }

  @media only screen and (max-width: ${metrics.tablet}) {
    width: 100%;
    padding: 40px 0px 33px 0px;
    justify-content: center;
  }
`;

export const Pattern = styled(PatternImage)``;

export const PointsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  @media only screen and (max-width: ${metrics.tablet}) {
    flex-direction: row;
    overflow: auto;
    white-space: nowrap;
    margin-left: 0px;

    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }

    :first-child {
      padding-left: 32px;
    }
  }
`;

export const PointText = styled.div`
  color: ${colors.secondary};
  font-size: ${fonts.size.subtitle};
  opacity: 0.5;

  @media only screen and (max-width: ${metrics.tablet}) {
    font-size: ${fonts.size.regular};
    padding-bottom: 14px;
  }
`;

export const PointIndicator = styled.div`
  width: 100px;
  height: 2px;
  background-color: ${colors.secondary};
  margin-left: 30px;
  opacity: 0.5;

  @media only screen and (max-width: ${metrics.tablet}) {
    display: none;
  }
`;

export const Point = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  margin: 15px 0px;
  cursor: pointer;

  ${PointText} {
    opacity: ${(props) => (props.selected ? 1 : 0.5)};

    @media only screen and (max-width: ${metrics.tablet}) {
      border-bottom: ${(props) => (props.selected ? '3px solid #FFFFFF' : '3px solid transparent')};
    }
  }

  ${PointIndicator} {
    opacity: ${(props) => (props.selected ? 1 : 0.5)};
  }

  @media only screen and (max-width: ${metrics.tablet}) {
    padding: '0px';
    margin: 0px 25px 0px 0px;
  }
`;

export const Content = styled.div`
  @media only screen and (max-width: ${metrics.tablet}) {
    max-width: 450px;
  }
`;

export const Title = styled.div`
  color: ${colors.secondary};
  font-size: ${fonts.size.title};
  line-height: 55px;
  margin-bottom: 20px;
  max-width: 450px;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    font-size: ${fonts.size.header};
    line-height: 45px;
    margin-bottom: 15px;
  }
`;

export const SubTitle = styled.div`
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: ${fonts.weight.light};
  color: ${colors.secondary};
  margin-bottom: 40px;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    font-size: 13px;
    margin-bottom: 30px;
  }
`;

export const Text = styled.div`
  color: ${colors.secondary};
  font-size: ${fonts.size.subtitle};
  white-space: break-spaces;
`;

export const List = styled.div``;

export const Item = styled.div`
  margin-bottom: 11px;
  display: flex;
  align-items: flex-start;
`;

export const ItemText = styled.div`
  color: ${colors.secondary};
`;

export const Indicator = styled.div`
  width: 14px;
  border-bottom: 2px solid #ffffff;
  margin-right: 15px;
  margin-top: 12px;
  flex-shrink: 0;
`;

export const Quote = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuoteAuthorImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 75px;
  align-self: center;
  margin-bottom: 40px;
  object-fit: cover;

  @media only screen and (max-width: ${metrics.mobile}) {
    width: 120px;
    height: 120px;
    border-radius: 60px;
  }
`;

export const QuoteTextWrapper = styled.div`
  min-height: 150px;

  @media only screen and (max-width: ${metrics.mobile}) {
    min-height: 200px;
  }
`;

export const QuoteText = styled.div`
  color: ${colors.fontSecondary};
  font-size: ${fonts.size.regular};
  line-height: 23px;
  letter-spacing: 0.05em;
  position: relative;

  &:before {
    content: '“';
    font-size: ${fonts.size.title};
    position: absolute;
    top: -5px;
    left: -25px;
  }

  &:after {
    content: '”';
    font-size: ${fonts.size.title};
    position: absolute;
    bottom: -20px;
    right: 0;
  }
`;

export const QuoteList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 56px;
`;

export const PreviousQuote = styled(BackArrowIcon)`
  height: 15px;
  cursor: pointer;
`;

export const QuoteAuthor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const QuoteAuthorName = styled.div`
  color: ${colors.fontSecondary};
  font-weight: ${fonts.weight.semiBold};
  margin-bottom: 4px;
`;

export const QuoteAuthorPosition = styled.div`
  color: rgba(255, 255, 255, 0.72);
  font-size: ${fonts.size.medium};
`;

export const NextQuote = styled(BackArrowIcon)`
  height: 15px;
  transform: rotate(180deg);
  cursor: pointer;
`;

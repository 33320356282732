import styled from 'styled-components';

import ArrowIcon from 'assets/down-arrow.svg';
import fonts from 'styles/fonts';

export const Popup = styled.div`
  border-top: 1px solid #daecfb;
  padding: ${(props) => (props.expanded ? '29px 140px 29px 140px' : '15px 140px 15px 140px')};
  background: #e5f3ff;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.expanded ? 'space-between' : 'flex-end')};
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
`;

export const PopupText = styled.div`
  display: flex;

  :before {
    content: '';
    height: 32px;
    border-left: 2px solid #a03544;
    padding-right: 24px;
  }
`;

export const PopupTextWrapper = styled.div``;

export const Text = styled.div`
  font-size: ${fonts.size.small};
  line-height: 18px;
`;

export const ExpandButton = styled.img.attrs({
  src: ArrowIcon,
})`
  cursor: pointer;
  transform: ${(props) => !props.expanded && 'rotate(180deg)'};
  padding: 10px;
  margin: -10px;
`;

export const ModalContent = styled.div``;

export const ModalHeader = styled.div`
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 18px;

  :after {
    content: '';
    width: 77px;
    border-bottom: 2px solid #343360;
    padding-top: 18px;
  }
`;

export const ModalText = styled.div`
  padding: 0px 9px 0px 9px;
`;

import styled from 'styled-components';

import colors from 'styles/colors';
import fonts from 'styles/fonts';

export const StyledButton = styled.button<{ small?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? colors.backgroundDisabled : colors.backgroundButton)};
  border: 2px solid ${(props) => (props.disabled ? colors.backgroundDisabled : colors.backgroundButton)};
  color: ${colors.fontSecondary};
  font-size: ${(props) => (props.small ? fonts.size.tiny : fonts.size.medium)};
  letter-spacing: 0.1em;
  border-radius: ${(props) => !props.small && '2px'};
  text-transform: uppercase;
  width: 100%;
  height: ${(props) => (props.small ? '23px' : '45px')};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 2px solid ${colors.primary};
    background-color: ${colors.backgroundPage};
    color: ${colors.fontPrimary};
  }

  pointer-events: ${(props) => props.disabled && 'none'};
`;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import Layout from 'components/Layout';
import ActivateAccount from 'pages/ActivateAccount';
import CancelPaypalOrder from 'pages/CancelPaypalOrder';
import CompletePaypalOrder from 'pages/CompletePaypalOrder';
import Confirmation from 'pages/Confirmation';
import ConfirmSubscription from 'pages/ConfirmSubscription';
import DeleteData from 'pages/DeleteData';
import ForgotPassword from 'pages/ForgotPassword';
import NoPageFound from 'pages/NoPageFound';
import Payment from 'pages/Payment';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import ProductSelection from 'pages/ProductSelection';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import RitualsSubscription from 'pages/RitualsSubscription';
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import * as purchaseActions from 'resources/purchase/purchase.actions';
import * as userSelectors from 'resources/user/user.selectors';

import { ErrorModalContextProvider } from './components/ErrorModal';

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const authenticated = useSelector((state) => userSelectors.getAuthenticated(state));
  const emailVerified = useSelector((state) => userSelectors.getEmailVerified(state));

  const match = useRouteMatch('/purchase/:productId');

  useEffect(() => {
    if (match) {
      const { productId } = match.params;
      dispatch(purchaseActions.setInitialProduct(productId));
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('token');
      const query = searchParams.toString();

      if (authenticated) {
        history.push(`/payment/${productId}${query ? `?${searchParams.toString()}` : ''}`);
      }
    }
  }, [match, dispatch]);

  if (authenticated) {
    return (
      <Layout>
        <Switch>
          <Route exact path="/" component={ProductSelection} />
          <Route path="/profile" component={Profile} />
          <Route path="/payment" component={Payment} />
          <Route path="/sign-in" component={SignIn} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password/:uidb64/:token" component={ResetPassword} />
          <Route path="/complete/:orderId/:productId" component={CompletePaypalOrder} />
          <Route path="/cancel/:orderId" component={CancelPaypalOrder} />
          {location.state && location.state.canViewConfirmationPage && (
            <Route path="/confirmation/:id" component={Confirmation} />
          )}
          {!emailVerified && <Route path="/activate/:pk/:token" component={ActivateAccount} />}
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/delete-data" component={DeleteData} />
          <Route path="/subscribe/:sub" component={RitualsSubscription} />
          <Route path="/subscription-confirmation" component={ConfirmSubscription} />
          <Redirect path="/rituals/monthly" to="/subscribe/monthly" />
          <Redirect path="/rituals/annual" to="/subscribe/annual" />
          <Route component={NoPageFound} />
        </Switch>
      </Layout>
    );
  }

  return (
    <ErrorModalContextProvider>
      <Layout>
        <Switch>
          <Route path="/sign-in" component={SignIn} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password/:uidb64/:token" component={ResetPassword} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/delete-data" component={DeleteData} />
          <Route path="/subscription-confirmation" component={ConfirmSubscription} />
          <Redirect from="/rituals/monthly" to="/sign-in?rituals=monthly" />
          <Redirect from="/rituals/annual" to="/sign-in?rituals=annual" />
          {match?.params.productId ? <Redirect to="/sign-up" /> : <Redirect to="/sign-in" />}
        </Switch>
      </Layout>
    </ErrorModalContextProvider>
  );
};

export default App;

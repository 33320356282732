import styled from 'styled-components';

import { ReactComponent as PatternBottomImage } from 'assets/pattern-sign-in-bottom.svg';
import { ReactComponent as PatternTopImage } from 'assets/pattern-sign-in-top.svg';
import colors from 'styles/colors';
import { Link } from 'styles/commonStyledComponents';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const StyledPage = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;

  @media only screen and (max-width: ${metrics.mobile}) {
    flex-direction: column;
  }
`;

export const SideView = styled.div`
  background-color: ${colors.backgroundTheme};
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media only screen and (max-width: ${metrics.mobile}) {
    display: none;
  }
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Quote = styled.div`
  max-width: 400px;

  @media only screen and (max-width: ${metrics.tablet}) {
    max-width: 300px;
  }
`;

export const QuoteText = styled.div`
  color: ${colors.fontSecondary};
  font-size: ${fonts.size.regular};
  line-height: 23px;
  letter-spacing: 0.05em;
  position: relative;

  &:before {
    content: '“';
    font-size: ${fonts.size.title};
    position: absolute;
    top: 0px;
    left: -20px;
  }

  &:after {
    content: '”';
    font-size: ${fonts.size.title};
    position: absolute;
    bottom: -5px;
    right: 0;
  }
`;

export const QuoteAuthor = styled.div`
  color: rgba(255, 255, 255, 0.77);
  font-size: ${fonts.size.subtitle};
  font-weight: ${fonts.weight.semiBold};
  line-height: 21px;
  margin-top: 30px;
`;

export const SignInPart = styled.div`
  width: 50%;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: ${metrics.mobile}) {
    width: 100%;
  }

  @media only screen and (max-width: ${metrics.largeTablet}) {
    padding: 42px 32px;
  }
`;

export const SignIn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 450px;
  width: 100%;
`;

export const SignInTitle = styled.h1`
  font-size: ${fonts.size.header};
  font-weight: 400;
  margin-bottom: 30px;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: 26px;
  }
`;

export const PatternBottom = styled(PatternBottomImage)``;

export const PatternTop = styled(PatternTopImage)``;

export const SignInMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SignInFotter = styled.div`
  margin-top: 30px;
`;

export const ForgotPasswordLink = styled(Link)`
  margin-right: 19px;
`;

export const PrivacyPolicyLink = styled(Link)``;

import { useCallback, useState } from 'react';

const useModal = (defaultValue) => {
  const [visible, setModalVisible] = useState(defaultValue);
  const [text, setText] = useState();

  const setHidden = useCallback(() => {
    setModalVisible(false);
  }, []);

  const setVisible = useCallback((modalText) => {
    setModalVisible(true);
    setText(modalText);
  }, []);

  return [visible, setHidden, setVisible, text];
};

export default useModal;

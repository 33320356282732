import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ReactComponent as Info } from 'assets/info.svg';
import colors from 'styles/colors';
import { TextLink } from 'styles/commonStyledComponents';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const LegacyUserWrapper = styled.div``;

export const LegacyUser = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;

  cursor: pointer;
`;

export const Tooltip = styled.div`
  padding: 16px 16px 19px 20px;
  position: absolute;
  background-color: #f8fbff;
  border: 1px solid rgba(45, 79, 122, 0.08);
  box-shadow: 2px 2px 8px rgba(220, 216, 216, 0.25);
  border-radius: 4px;
  bottom: 30px;
  right: -70px;
  width: 400px;
  font-size: 11px;
  line-height: 18px;
  color: #2d4f7a;
`;

export const TooltipMobile = styled.div`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-x: hidden;

  background-color: rgba(26, 25, 75, 0.33);
`;

export const TooltipMobileContent = styled.div`
  background-color: #f8fbff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 312px;
  padding: 16px 19px 30px 24px;

  position: absolute;

  top: 30px;
  right: 0px;

  @media only screen and (max-width: ${metrics.mobile}) {
    left: 0px;
  }
`;

export const TooltipMobileClose = styled(CloseIcon)`
  align-self: flex-end;
`;

export const TooltipMobileText = styled.div`
  font-size: 11px;
  line-height: 18px;
  color: #2d4f7a;
  margin-top: 7px;
`;

export const LegacyUserLink = styled(TextLink)`
  &:hover {
    color: ${colors.link};
  }

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: ${fonts.size.small};
  }
`;

export const InfoIcon = styled(Info)`
  width: 14px;
  height: auto;
  margin-right: 6px;
`;

import React from 'react';

import ErrorModal from './ErrorModal';

interface IErrorModalContext {
  isOpen: boolean;
  closeModal: () => void;
  openModal: (content?: React.ReactNode) => void;
  content?: React.ReactNode;
}

export const ErrorModalContext = React.createContext<IErrorModalContext>({
  isOpen: false,
  closeModal: () => {},
  openModal: () => {},
});

export function ErrorModalContextProvider({ children }: React.PropsWithChildren) {
  const [content, setContent] = React.useState<React.ReactNode>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const closeModal = React.useCallback(() => setIsOpen(false), []);
  const openModal = React.useCallback((content?: React.ReactNode) => {
    setIsOpen(true);
    setContent(content);
  }, []);

  const value = React.useMemo(
    () => ({
      isOpen,
      content,
      closeModal,
      openModal,
    }),
    [isOpen, content, closeModal, openModal]
  );

  return (
    <ErrorModalContext.Provider value={value}>
      {children}
      <ErrorModal visible={isOpen} text={content} onClose={closeModal} />
    </ErrorModalContext.Provider>
  );
}

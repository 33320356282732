import React from 'react';
import PropTypes from 'prop-types';

import * as SC from './RadioButton.styled';

const RadioButton = ({ selected }) => (
  <SC.StyledRadioButton>
    <SC.RadioButton selected={selected} />
  </SC.StyledRadioButton>
);

RadioButton.propTypes = {
  selected: PropTypes.bool.isRequired,
};

export default RadioButton;

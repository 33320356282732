import { combineReducers } from 'redux';

import countries from './countries/countries.reducer';
import product from './product/product.reducer';
import purchase from './purchase/purchase.reducer';
import user from './user/user.reducer';

export default combineReducers({
  countries,
  purchase,
  product,
  user,
});

import sort from 'fast-sort';

import * as productApi from './product.api';

export const PURCHASED_PRODUCTS = 'PURCHASED_PRODUCTS';
export const AVAILABLE_PRODUCTS = 'AVAILABLE_PRODUCTS';

export const getPurchasedProducts = () => async (dispatch) => {
  const products = await productApi.getPurchasedProducts();
  const sortedItems = sort(
    products.results.filter((product) => product.user_product && product.user_product.charged)
  ).asc([(p) => p.courses.length, (p) => p.full_price]);
  dispatch({ type: PURCHASED_PRODUCTS, products: sortedItems });
  return sortedItems;
};

export const getAvailableProducts = () => async (dispatch) => {
  const products = await productApi.getAvailableProducts();
  const sortedItems = sort(products.results).asc([(p) => p.courses.length, (p) => p.full_price]);
  dispatch({ type: AVAILABLE_PRODUCTS, products: sortedItems });
  return sortedItems;
};

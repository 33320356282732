import React from 'react';
import PropTypes from 'prop-types';

import * as SC from './BackNavigation.styled';

const BackNavigation = ({ title, to }) => (
  <SC.BackNavigation to={to}>
    <SC.BackNavigationArrow />
    <SC.BackNavigationText>{title}</SC.BackNavigationText>
  </SC.BackNavigation>
);

BackNavigation.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default BackNavigation;

import styled from 'styled-components';

import { ReactComponent as Logo } from 'assets/logo.svg';
import colors from 'styles/colors';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  @media only screen and (max-width: ${metrics.mobile}) {
    object-fit: contain;
  }
`;

export const ProductLogo = styled(Logo)`
  width: 88px;
  height: auto;
`;

export const ProductImageContainer = styled.div`
  background-color: ${(props) => (props.color ? props.color : colors.darkTheme)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 160px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  @media only screen and (max-width: ${metrics.mobile}) {
    height: ${(props) => (props.withPrice ? '62px' : '105px')};

    ${ProductLogo} {
      width: ${(props) => (props.withPrice ? '52px' : '64px')};
      height: auto;
    }
  }
`;

export const ProductCard = styled.div`
  max-width: 360px;
  width: 100%;
  height: 100%;

  box-shadow: ${(props) =>
    props.selected ? '0px 0px 12px rgba(120, 141, 209, 0.58)' : '4px 4px 10px rgba(200, 206, 228, 0.25)'};
  border-radius: 4px;
  margin-right: 40px;

  margin-top: 15px;
  margin-bottom: 15px;

  @media only screen and (min-width: ${metrics.minNonMobile}) {
    margin-top: ${(props) => props.selected && '0px'};
    margin-bottom: ${(props) => props.selected && '0px'};

    ${ProductImageContainer} {
      height: ${(props) => props.selected && '190px'};
    }

    ${ProductLogo} {
      width: ${(props) => props.selected && '110px'};
    }

    :hover {
      box-shadow: ${(props) => !props.selected && '4px 4px 10px rgba(200, 206, 228, 0.5)'};
      cursor: pointer;
    }
  }

  @media only screen and (max-width: ${metrics.mobile}) {
    max-width: 330px;
  }
`;

export const ProductInfoContainer = styled.div`
  padding: 30px 23px 30px 20px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${metrics.mobile}) {
    padding: 0px;
    flex-direction: column;
  }
`;

export const ProductInfo = styled.div`
  position: relative;
`;

export const ProductInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  @media only screen and (max-width: ${metrics.mobile}) {
    padding: 20px 14px 13px 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
`;

export const ProductNameWrapper = styled.div`
  margin-bottom: 7px;
`;

export const ProductName = styled.div`
  font-size: ${fonts.size.subtitle};

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: ${fonts.size.regular};
    margin-bottom: 0px;
  }
`;

export const ProductSecondName = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
`;

export const ProductAccess = styled.div`
  text-transform: uppercase;
  font-weight: ${fonts.weight.light};
  font-size: ${fonts.size.small};
  letter-spacing: 0.1em;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: ${fonts.size.tiny};
  }
`;

export const ProductStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Status = styled.div`
  color: #34cc82;
  font-size: ${fonts.size.small};
  text-transform: uppercase;
  font-weight: ${fonts.weight.medium};

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: ${fonts.size.extrSmall};
  }
`;

export const ProductLastDay = styled.div`
  text-transform: uppercase;
  font-size: ${fonts.size.tiny};
  letter-spacing: 0.1em;
`;

export const PriceContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const PreviousPrice = styled.div`
  position: relative;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #b3b3b3;
    transform: rotate(-7deg);
  }

  @media only screen and (max-width: ${metrics.mobile}) {
    top: 0px;
    font-size: 10px;
  }
`;

export const CurrentPrice = styled.div`
  font-size: ${fonts.size.subtitle};
  font-weight: ${fonts.weight.medium};
  margin-bottom: 10px;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-size: ${fonts.size.small};
    font-weight: ${fonts.weight.semiBold};
    align-self: center;
  }
`;

export const MobileButtonWrapper = styled.div`
  width: 123px;
  margin: auto;
  padding: 13px 0px;
`;

const stripeStyles = {
  base: {
    fontFamily: 'Montserrat, sans-serif',
    letterSpacing: '0.02em',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '45px',
    color: '#000',

    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.33)',
      fontWeight: '400',
    },
  },
  invalid: {
    color: '#CD2028',
  },
};

export default stripeStyles;

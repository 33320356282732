import styled from 'styled-components';

import { ReactComponent as CourseLogo } from 'assets/course-logo.svg';
import { ReactComponent as Promo } from 'assets/promo-tag.svg';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const ProductInfo = styled.div`
  max-width: 450px;
  width: 100%;
`;

export const MobileSectionTitle = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.15em;
  margin-bottom: 33px;
`;

export const CourseInfo = styled.div`
  padding-bottom: 37px;
  border-bottom: 1px solid rgba(91, 72, 72, 0.14);
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${metrics.mobile}) {
    flex-direction: column;
    padding-bottom: 26px;
  }
`;

export const CourseInfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  @media only screen and (max-width: ${metrics.mobile}) {
    margin-top: 13px;
  }
`;

export const CourseImage = styled(CourseLogo)`
  width: 73px;
  height: auto;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    width: 45px;
  }
`;

export const CourseName = styled.div`
  padding: 0px 30px 0px 22px;

  @media only screen and (max-width: ${metrics.mobile}) {
    padding: 0px;
  }
`;

export const CourseTitle = styled.div`
  font-weight: ${fonts.weight.medium};
  letter-spacing: 0.1em;

  @media only screen and (max-width: ${metrics.mobile}) {
    font-weight: ${fonts.weight.regular};
  }
`;

export const CourseSubtitle = styled.div`
  letter-spacing: 0.05em;

  @media only screen and (max-width: ${metrics.mobile}) {
    display: none;
  }
`;

export const CoursePrice = styled.div`
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #000000;
  font-size: ${fonts.size.small};
  font-weight: ${fonts.weight.semiBold};
`;

export const PromoCode = styled.form`
  padding: 22px 0px 15px 0px;
  border-bottom: 1px solid rgba(91, 72, 72, 0.14);
`;

export const PromoCodeDescription = styled.p`
  color: #cf0106;
  line-height: 1.5;
  margin-bottom: 10px;
`;

export const PromoCodeLabel = styled.div`
  margin-bottom: 13px;
`;

export const PromoCodeContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ApllyPromoCodeButton = styled.div`
  margin-bottom: 15px;
  margin-left: 20px;
  width: 128px;
`;

export const Discounts = styled.div`
  padding: 22px 0px 27px 0px;
  border-bottom: 1px solid rgba(91, 72, 72, 0.14);
`;

export const Discount = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DiscountKey = styled.div`
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.72);
  font-size: ${fonts.size.small};
`;

export const DiscountPrice = styled.div`
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #000000;
  font-size: ${fonts.size.small};
  font-weight: ${fonts.weight.semiBold};
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px 0px 27px 0px;
  align-items: flex-end;
`;

export const TotalText = styled.div`
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-size: ${fonts.size.subtitle};
  font-weight: ${fonts.weight.medium};
`;

export const TotalPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PreviousPrice = styled.div`
  letter-spacing: 0.1em;
  color: rgba(0, 0, 0, 0.3);
  font-size: ${fonts.size.regular};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #b3b3b3;
    transform: rotate(-7deg);
  }
`;

export const ResultPrice = styled.div`
  letter-spacing: 0.15em;
  font-size: ${fonts.size.subtitle};
  font-weight: ${fonts.weight.semiBold};
`;

export const OrderButton = styled.div`
  width: 171px;
  margin-top: 34px;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    margin-left: auto;
    margin-top: 10px;
  }
`;

export const PromoCodeWithTag = styled.div`
  display: flex;
  align-items: center;
`;

export const PromoTag = styled.div`
  background-color: #ededed;
  display: flex;
  align-items: center;
  margin-left: 17px;
  height: 21px;
  padding: 0px 10px 0px 5px;
`;

export const PromoTagIcon = styled(Promo)``;

export const PromoTagTitle = styled.div`
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.72);
  font-size: 8px;
  line-height: 16px;
`;

import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/close.svg';

import Button from '../Button';
import Modal from '../Modal';
import * as SC from './ErrorModal.styled';

interface Props {
  visible?: boolean;
  onClose: () => void;
  text?: React.ReactNode;
}

const ErrorModal = ({ visible, onClose, text }: Props) => {
  return (
    <Modal visible={visible}>
      <SC.Close onClick={onClose} data-testid="error-modal-close">
        <CloseIcon />
      </SC.Close>
      <SC.Error>Error</SC.Error>
      {Array.isArray(text) ? text.map((error) => <SC.Text key={error}>{error}</SC.Text>) : <SC.Text>{text}</SC.Text>}
      <SC.ButtonWrapper>
        <Button title="Close" onClick={onClose} />
      </SC.ButtonWrapper>
    </Modal>
  );
};

export default ErrorModal;

import React, { useCallback, useState } from 'react';

import SuccessModal from 'components/SuccessModal';
import useModal from 'hooks/useModal';
import metrics from 'styles/metrics';

import { useMedia } from '../../../../hooks/useMedia';
import * as SC from './InfoPopup.styled';

const InfoPopup = () => {
  const [expanded, setExpanded] = useState(true);
  const isMobile = useMedia([`(max-width: ${metrics.largeTablet})`], [true], false);
  const [visibleSuccessModal, setHiddenSuccessModal] = useModal(true);

  const onExpandPopup = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  if (isMobile) {
    return (
      <SuccessModal visible={visibleSuccessModal} onClose={setHiddenSuccessModal} withoutButton>
        <SC.ModalContent>
          <SC.ModalHeader>Important information</SC.ModalHeader>
          <SC.ModalText>
            {`This product is viewed with the Dandapani iOS and Android mobile app. 
            You must be able to download either of those applications to move forward with this product.`}
          </SC.ModalText>
        </SC.ModalContent>
      </SuccessModal>
    );
  }

  return (
    <SC.Popup expanded={expanded}>
      {expanded && (
        <SC.PopupText>
          <SC.PopupTextWrapper>
            <SC.Text>This product is viewed with the Dandapani iOS and Android mobile app.</SC.Text>
            <SC.Text>
              {`You must be able to download either of 
              those applications to move forward with this product.`}
            </SC.Text>
          </SC.PopupTextWrapper>
        </SC.PopupText>
      )}
      <SC.ExpandButton onClick={onExpandPopup} expanded={expanded} />
    </SC.Popup>
  );
};

export default InfoPopup;

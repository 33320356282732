import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from 'assets/down-arrow.svg';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const PaymentSection = styled.div`
  min-height: calc(100vh - 2 * ${metrics.headerHeight});
  display: flex;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    display: block;
  }
`;

export const Payment = styled.form`
  width: 50%;
  padding: 76px 32px 0px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    width: 100%;
    padding: 46px 32px 60px 32px;
  }
`;

export const PaymentForm = styled.div`
  max-width: 450px;
  width: 100%;
`;

export const ProductInfo = styled.div`
  width: 50%;
  padding: 76px 32px 100px 32px;
  background-color: #fcfcfc;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 52px 32px 54px 32px;
  }
`;

export const ProductInfoBelow = styled.div`
  position: absolute;
  bottom: 62px;
  left: calc(50% - 73px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    position: initial;
    margin-top: 62px;
  }
`;

export const ProductInfoBelowText = styled.div`
  text-transform: uppercase;
  font-size: ${fonts.size.small};
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 10px;
`;

export const ProductInfoBelowIcon = styled(ArrowIcon)``;

import React from 'react';
import PropTypes from 'prop-types';

import Header from 'components/Header';

import * as SC from './Layout.styled';

const Layout = ({ children }) => {
  return (
    <SC.StyledApp>
      <Header />
      {children}
    </SC.StyledApp>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;

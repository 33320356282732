import React from 'react';
import { AxiosError } from 'axios';
import capitalize from 'capitalize';
import { camelize } from 'humps';
import { FieldError, get, Path, useForm as useReactHookForm, UseFormProps, UseFormReturn } from 'react-hook-form';

// import { ErrorModalContext } from '../components/ErrorModal';

export const required = 'This field is required.' as const;

export const minLength = 'The text is too short.' as const;

export const maxLength = 'The text is too long.' as const;

export const FORM_ERROR = '@form_error' as const;

export const FIELD_ERROR = '@api' as const;

export interface FormError {
  [FORM_ERROR]?: unknown;
}

export function getFieldError(fieldError?: FieldError): React.ReactNode {
  if (!fieldError) {
    return null;
  }

  switch (fieldError.type) {
    case 'required':
      return required;
    case 'minLength':
      return minLength;
    case 'maxLength':
      return maxLength;
    case 'validate':
      return fieldError.message;
    case FIELD_ERROR:
      return fieldError.message;
    default:
      return null;
  }
}

const concatArrayError = (errors: string[]) => errors.map((error) => capitalize(error)).join(' ');

export function createFormError(_result: unknown): Record<string, string> {
  if (!_result || !('status' in (_result as any))) {
    return {
      [FORM_ERROR]: 'Unknown error has occurred',
    };
  }

  const result = _result as { data?: any; status: number };
  const { status } = result;

  let details = '';

  if (result.data) {
    const { data: payload } = result;

    details = payload.detail || '';

    if (status === 400 && typeof payload === 'object') {
      return Object.entries(payload).reduce((pv, [_key, value]) => {
        const key = camelize(_key);

        if (!value) {
          return pv;
        }

        if (typeof value === 'string') {
          return value;
        }

        return {
          ...pv,
          [key === 'nonFieldErrors' ? FORM_ERROR : key]: concatArrayError(value as any),
        };
      }, {});
    }
  }

  if (status === -1) {
    return {
      [FORM_ERROR]: 'Connection timeout. Please, check your Internet connection.',
    };
  }

  if (status === 404) {
    return {
      [FORM_ERROR]: details || 'Not found.',
    };
  }

  if (status === 401) {
    return {
      [FORM_ERROR]: details || 'Unauthorized.',
    };
  }

  if (status === 403) {
    return {
      [FORM_ERROR]: details || 'Forbidden.',
    };
  }

  if (status >= 400) {
    return {
      [FORM_ERROR]: 'Internal server error. Please, try again later.',
    };
  }

  return {
    [FORM_ERROR]: 'Unknown error has occurred',
  };
}

export function useForm<FormValues extends FormError, TContext extends object = object>(
  config?: UseFormProps<FormValues, TContext>
): UseFormReturn<FormValues, TContext> & {
  mapFormErrors: (error: Error | AxiosError | unknown) => void;
  globalError: string | null;
} {
  // const { openModal } = React.useContext(ErrorModalContext);
  const [globalError, setGlobalError] = React.useState<string | null>(null);

  const form = useReactHookForm<FormValues, TContext>(config);
  const {
    formState: { errors, isSubmitting },
    clearErrors,
    setError,
    control,
  } = form;

  const formError = get(errors, FORM_ERROR);

  React.useEffect(() => {
    if (formError && formError.type === FIELD_ERROR && formError.message) {
      // openModal(formError.message);
      setGlobalError(formError.message);
      clearErrors(FORM_ERROR as Path<FormValues>);
    }
  }, [formError, clearErrors]);

  React.useEffect(() => {
    if (isSubmitting) {
      setGlobalError(null);
    }
  }, [isSubmitting]);

  /**
   *
   * @param error - new RejectedResult().toFormError()
   */
  const mapFormErrors = React.useCallback(
    (error: unknown) => {
      const formError = createFormError(error);

      Object.entries(formError).forEach(([key, value]) => {
        /**
         * By default react-hook-form blocks the submit button if there are any registered errors.
         * Code checks for exisiting fields or global form error to mark the field as error in order
         * to prevent the unexpected blocking submit button.
         */
        if (key === FORM_ERROR || control._fields[key]) {
          setError(key as Path<FormValues>, {
            type: FIELD_ERROR,
            message: value,
          });
        }
      });
    },
    [control, setError]
  );

  return {
    ...form,
    mapFormErrors,
    globalError,
  };
}

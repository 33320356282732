import React from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';

import * as SC from './ProviderRadio.styled';

export enum ProviderRadioValue {
  Stripe = 'stripe',
  PayPal = 'paypal',
}

interface Props {
  value: ProviderRadioValue;
  onChange: (value: ProviderRadioValue) => void;
}

export const ProviderRadio = React.memo(({ value, onChange }: Props) => {
  return (
    <SC.Tabs value={value} aria-label="How would you like to pay?" orientation="horizontal" onValueChange={onChange}>
      <SC.Tab id={ProviderRadioValue.Stripe} value={ProviderRadioValue.Stripe}>
        <SC.TabText>Pay By Card</SC.TabText>
        <RadioGroup.Indicator />
      </SC.Tab>
      <SC.Tab id={ProviderRadioValue.PayPal} value={ProviderRadioValue.PayPal}>
        <SC.PaypalTabText>
          Pay With <span className="sr-only">PayPal</span>
        </SC.PaypalTabText>
        <SC.PaypalTabImage aria-hidden />
      </SC.Tab>
    </SC.Tabs>
  );
});

import React from 'react';
import ReactDOM from 'react-dom';

import * as SC from './Modal.styled';

const Modal = ({ visible, children }) =>
  visible &&
  ReactDOM.createPortal(
    <SC.Modal>
      <SC.Content>{children}</SC.Content>
    </SC.Modal>,
    document.getElementById('root')
  );

export default Modal;

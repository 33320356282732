import * as RadioGroup from '@radix-ui/react-radio-group';
import styled from 'styled-components';

import { ReactComponent as PaypalImage } from 'assets/paypal.svg';
import fonts from 'styles/fonts';
import metrics from 'styles/metrics';

export const Tabs = styled(RadioGroup.Root)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 65px;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    margin-bottom: 52px;
  }
`;

export const Tab = styled(RadioGroup.Item)`
  padding-bottom: 17px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 46%;
  display: flex;
  justify-content: center;
  align-items: center;

  &#stripe[data-state='checked'] {
    border-bottom: 2px solid #000000;
    padding-bottom: 16px;
  }

  &#paypal[data-state='checked'] {
    border-bottom: 2px solid #019de4;
    padding-bottom: 16px;
  }

  &#paypal[data-state='unchecked'] {
    opacity: 0.6;
  }
`;

export const TabText = styled.label`
  letter-spacing: 0.25em;
  text-transform: uppercase;
  font-size: ${fonts.size.small};

  @media only screen and (max-width: ${metrics.largeTablet}) {
    letter-spacing: 0.1em;
  }
`;

export const PaypalTabText = styled(TabText)`
  color: #179bd7;
  margin-right: 10px;

  @media only screen and (max-width: ${metrics.largeTablet}) {
    margin-right: 3px;
  }
`;

export const PaypalTabImage = styled(PaypalImage)`
  @media only screen and (max-width: ${metrics.largeTablet}) {
    height: 15px;
    width: auto;
  }

  @media only screen and (max-width: ${metrics.mobile}) {
    height: 12px;
    width: auto;
  }
`;
